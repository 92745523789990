import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import { Button, Size, TextEditor, Theme } from 'components/base';
import { deleteStudentAnswerFile, getStudentAnswerFile, uploadStudentAnswerFile } from 'actions/lesson';
import { File } from 'models/lesson.interface';

interface Props {
	question: string;
	value: string;
	onChange: (value: string) => void;
	onFileUpload: (fileId: string) => void;
	onDeleteFile: () => void;
	comment: string | undefined;
	fileId?: string;
	disabled?: boolean;
}

export const TextTask = (props: Props) => {
	const { question, value, onChange, comment, fileId, onFileUpload, onDeleteFile, disabled = false } = props;

	const [isLoading, setIsLoading] = useState(false);
	const [loadingPercent, setLoadingPercent] = useState(0);
	const [file, setFile] = useState<File>();

	useEffect(() => {
		if (fileId && !file) {
			getStudentAnswerFile(fileId).then((result: File) => {
				setFile(result);
			});
		}
	}, [fileId]);

	const uploadDocument = () => {
		const input = document.createElement('input');
		input.accept = 'application/pdf, application/msword, .xlsx, .xls, .docx, .doc';
		input.type = 'file';

		const config = {
			onUploadProgress: (progressEvent: ProgressEvent) => {
				setLoadingPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			}
		}

		input.onchange = e => {
			setIsLoading(true);
			setFile({ file: '', id: '', name: (e.target as any).files[0].name });
			uploadStudentAnswerFile((e.target as any).files[0], config).then((result) => {
				setFile(result);
				onFileUpload(result?.id);
			}).finally(() => {
				setIsLoading(false);
			});
		}

		input.click();
	}

	const onDeleteDocument = () => {
		deleteStudentAnswerFile(file?.id).then(() => {
			setFile(undefined);
			onDeleteFile();
		});
	}

	return (
		<>
			<p
				dangerouslySetInnerHTML={{ __html: question }}
				className={styles.block__text}
			></p>
			<TextEditor
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
			{
				!file && !disabled &&
				<Button
					theme={Theme.SECONDARY}
					size={Size.S}
					onClick={uploadDocument}
					className={styles.block__button}
				>
					Прикрепить файл
				</Button>
			}
			{
				file &&
				<div className={styles.block__file}>
					<span className={isLoading ? styles.block__file__name : ''}>{file?.name}</span>
					{
						isLoading && <span>{loadingPercent}%</span>
					}
					{
						!isLoading && !disabled &&
						<Button
							type='button'
							theme={Theme.THIRD}
							size={Size.S}
							onClick={onDeleteDocument}
							className={styles.block__file__button}
						>
							Удалить
						</Button>
					}
				</div>
			}
			{
				!!comment &&
				<section className={styles.block__comment}>
					<h4>Комментарий</h4>
					<p
						dangerouslySetInnerHTML={{ __html: comment }}
						className={styles.block__comment__text}
					></p>
				</section>
			}
		</>
	);
};

