import React, { useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, ProgressBar, Size, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';
import { downLoadDocument, removeLessonFile, uploadLessonFile } from 'actions/lesson';

interface Props {
	id: number;
	lessonId: string;
	file: { id: number, file: string, name: string, type: string };
	updateBlock?: (id: number, value: { id: number, name: string, type: string }, file: { file: string, id: number, name: string }) => void;
	className?: string;
}

export const DocumentBlock = (props: Props) => {
	const { id, lessonId, file, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const [isLoading, setIsLoading] = useState(false);
	const [isDownLoadingDoc, setIsDownLoadingDoc] = useState(false);
	const [loadingPercent, setLoadingPercent] = useState(0);

	const uploadDocument = () => {
		const input = document.createElement('input');
		input.accept = 'application/pdf, application/msword, .xlsx, .xls, .docx, .doc';
		input.type = 'file';

		const config = {
			onUploadProgress: (progressEvent: ProgressEvent) => {
				setLoadingPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			}
		}

		input.onchange = e => {
			setIsLoading(true);
			const fileType = (e.target as any).files[0]?.type;
			uploadLessonFile(lessonId, (e.target as any).files[0], config).then((result) => {
				setIsLoading(false);
				updateBlock(id, { id: result?.id, name: result?.name, type: fileType }, result);
				dispatch(setCurrentEditBlock(null));
			}, () => {
				setIsLoading(false);
			});
		}

		input.click();
	}

	const updateDocument = () => {
		removeLessonFile(file?.id).then(() => {
			uploadDocument();
		});
	}

	const downLoadDocumentHandle = () => {
		setIsDownLoadingDoc(true);
		downLoadDocument(file?.file, file?.name).then(() => {
			setIsDownLoadingDoc(false);
		});
	}

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
	}

	const getFileIcon = (type: string) => {
		switch (type) {
			case 'application/pdf':
				return 'pdf';
				break;

			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'docx';
				break;

			case 'application/msword':
				return 'doc';
				break;

			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'xlsx';
				break;

			case 'application/vnd.ms-excel':
				return 'xls';
				break;

			default:
				return '';
				break;
		}
	}

	return (
		<div className={cn(styles.container, className)}>
			{
				currentEditBlock === id ? (
					<>
						{
							isLoading && <ProgressBar percent={loadingPercent} />
						}
						<div className={styles.controls}>
							<Button
								theme={Theme.SECONDARY}
								size={Size.S}
								onClick={cancel}
								className={styles.button}
							>
								Отменить
							</Button>
							<Button
								theme={Theme.PRIMARY}
								size={Size.S}
								onClick={!!file?.id ? updateDocument : uploadDocument}
								className={styles.button}
							>
								{ !!file?.id ? 'Обновить документ' : 'Загрузить документ' }
							</Button>
						</div>
					</>
				) : (
					<>
						<section className={styles.file}>
							<BrowserView>
								<Icon icon={getFileIcon(file?.type)} width={48} height={56} />
							</BrowserView>
							<MobileView>
								<Icon icon={getFileIcon(file?.type)} width={37} height={44} />
							</MobileView>
							<h4>{file?.name}</h4>
						</section>
						<Button
							theme={Theme.PRIMARY}
							size={Size.S}
							onClick={downLoadDocumentHandle}
							className={cn(styles.button, styles.button_download)}
							isLoading={isDownLoadingDoc}
						>
							Скачать
						</Button>
					</>
				)
			}
		</div>
	);
};
