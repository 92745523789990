import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
	name: 'course',
	initialState: {
		data: null
	},
	reducers: {
		setCourse(state, action) {
			state.data = action.payload
		}
	}
});

export default courseSlice.reducer;
export const { setCourse } = courseSlice.actions;

