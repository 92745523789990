import { safelyPost, safelyPatch, safelyPut, safelyGet } from 'services/request';
import { User } from 'models/user.interface';
import { NotificationsList } from 'models/notification.interface';

export const auth = async(params: { email: string, password: string }) => {
	const result = await safelyPost<User>('users/login/', params);
	return result.response;
}

export const resetPassword = async(email: string) => {
	const result = await safelyPost('users/reset-password/', { email, backref: `${window.location.origin}/change-password` });
	return result.response;
}

export const updatePassword = async(params: { new_password1: string, new_password2: string, uid: string, token: string }) => {
	const result = await safelyPost<User>('users/reset-password-confirm/', params);
	return result.response;
}

export const updateAvatar = async(file: File | Blob) => {
	const formData = new FormData();
	formData.append('avatar', file, (file as any).name);
	const result = await safelyPut<User>('users/update-image/', formData);
	return result.response;
}

export const removeAvatar = async() => {
	const formData = new FormData();
	formData.append('avatar', '');
	const result = await safelyPut<User>('users/update-image/', formData);
	return result.response;
}

export const updateProfileData = async(params: { first_name: string, last_name: string, phone_number: string }) => {
	const result = await safelyPatch<User>('users/profile/', params);
	return result.response;
}

export const updateUserPassword = async (params: { old_password: string, new_password1: string, new_password2: string }) => {
	const result = await safelyPut<User>('users/change-password/', params);
	return result.response;
}

export const getNotifications = async () => {
	const result = await safelyGet<NotificationsList>('notifications/');
	return result.response;
}

export const markNotificationRead = async (id: number) => {
	const result = await safelyGet<Notification>(`notifications/${id}/`);
	return result.response;
}
