import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { StorageService } from 'services/storageService';
import { initAuthInterceptor } from 'services/interceptors';
import styles from './index.module.sass';
import { Button, Checkbox, Size, TextInput } from 'components/base';
import { auth } from 'actions/user';
import { User } from 'models/user.interface';
import { setCurrentUser } from 'reducers/userSlice';
import { Roles } from 'enums/roles.enum'

import logo from 'assets/images/logo.png';

interface Props {
	className?: string
}

const AuthFormSchema = Yup.object().shape({
	email: Yup.string()
		.email()
		.required('Заполните поле'),
	password: Yup.string()
		.required('Заполните поле')
});

export const AuthPage = (props: Props) => {
	const {
		className,
		...restProps
	} = props;

	const dispatch = useDispatch();
	const storage = StorageService.getInstance();

	const navigate = useNavigate();

	const [authError, setAuthError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div {...restProps} className={cn(styles.wrapper, className)}>
			<img src={logo} alt='logo' className={styles.logo} />
			<h1>Вход в личный кабинет</h1>
			<Formik
				initialValues={{
					email: '',
					password: '',
					rememberMe: false
				}}
				validationSchema={AuthFormSchema}
				onSubmit={(
					values
				) => {
					setIsLoading(true);
					auth({ email: values.email, password: values.password }).then((user: User) => {
						if (values.rememberMe) {
							storage.localStorageUser = user;
						} else {
							storage.sessionStorageUser = user;
						}

						dispatch(setCurrentUser(user));

						initAuthInterceptor();
						setIsLoading(false);

						if (user?.role === Roles.STUDENT) {
							navigate('/courses', { replace: true });
						}

						if (user?.role === Roles.METHODIST) {
							navigate('/methodist-courses', { replace: true });
						}

						if (user?.role === Roles.CURATOR) {
							navigate('/curator-courses', { replace: true });
						}
					}, (error) => {
						setAuthError(error?.response?.data?.message?.non_field_errors[0]);
						setIsLoading(false);
					});
				}}
			>
				{({ values, setFieldValue, isValid }) => (
					<Form className={styles.form} noValidate>
						<TextInput
							label='E-mail'
							value={values.email}
							onChange={event => setFieldValue('email', event.target.value)}
							className={styles.form__field}
						/>
						<TextInput
							label='Пароль'
							type='password'
							value={values.password}
							onChange={event => setFieldValue('password', event.target.value)}
							className={styles.form__field}
						/>
						<div className={styles.form__controls}>
							<Checkbox
								value={values.rememberMe}
								onChange={event => setFieldValue('rememberMe', !values.rememberMe)}
							>
								Запомнить меня
							</Checkbox>
						</div>
						<p className={styles.form__error}>
							{authError}
						</p>
						<Button
							stretch
							size={Size.L}
							disabled={!isValid}
							type='submit'
							isLoading={isLoading}
						>
							Войти
						</Button>
						<div className={styles.link}>
							<Link to='/reset-password'>Забыли пароль?</Link>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
