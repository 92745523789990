import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import cn from 'classnames';
import styles from './index.module.sass';
import { Button, Size, TextInput } from 'components/base';
import { updatePassword } from 'actions/user';

import logo from 'assets/images/logo.png';

interface Props {
	className?: string
}

const ChangePasswordFormSchema = Yup.object().shape({
	new_password1: Yup.string()
		.required('Заполните поле'),
	new_password2: Yup.string()
		.oneOf([Yup.ref('new_password1'), null], 'Пароли должны совпадать')
		.required('Заполните поле')
});

export const ChangePasswordPage = (props: Props) => {
	const {
		className,
		...restProps
	} = props;

	const [searchParams] = useSearchParams();

	const [changePasswordError, setChangePasswordError] = useState('');
	const [showSuccessSendLetterMessage, setShowSuccessSendLetterMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div {...restProps} className={cn(styles.wrapper, className)}>
			<img src={logo} alt='logo' className={styles.logo} />
			<h1>Смена пароля</h1>
			{
				showSuccessSendLetterMessage ? (
					<p className={styles.success_message}>Пароль успешно обновлен!</p>
				) : (
					<Formik
						initialValues={{
							new_password1: '',
							new_password2: ''
						}}
						validationSchema={ChangePasswordFormSchema}
						onSubmit={(
							values
						) => {
							const uid = searchParams.get('user_id') || '';
							const token = searchParams.get('token') || '';

							setIsLoading(true);

							updatePassword({
								...values,
								uid,
								token
							}).then(() => {
								setShowSuccessSendLetterMessage(true);
								setIsLoading(false);
							}, () => {
								setChangePasswordError('Что-то пошло не так');
								setIsLoading(false);
							});
						}}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form className={styles.form} noValidate>
								<TextInput
									label='Новый пароль'
									type='password'
									value={values.new_password1}
									onChange={event => setFieldValue('new_password1', event.target.value)}
									className={styles.form__field}
								/>
								<TextInput
									label='Повторите пароль'
									type='password'
									value={values.new_password2}
									onChange={event => setFieldValue('new_password2', event.target.value)}
									className={styles.form__field}
								/>
								<p className={styles.form__error}>
									{changePasswordError}
								</p>
								<Button
									stretch
									size={Size.L}
									disabled={!isValid}
									type='submit'
									isLoading={isLoading}
								>
									Обновить
								</Button>
							</Form>
						)}
					</Formik>
				)
			}
			<div className={styles.link}>
				<Link to='/auth'>Назад</Link>
			</div>
		</div>
	);
};
