import React, { ChangeEvent, FC } from 'react'
import styles from './index.module.sass';
import cn from 'classnames';

interface Props {
	value: string | number,
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
	checked?: boolean,
	name?: string,
	className?: string,
	disabled?: boolean,
}

export const Radio: FC<Props> = (props) => {
	const {
		value,
		onChange = (event: never) => {},
		checked = false,
		name = '',
		className = '',
		disabled,
		children,
		...restProps
	} = props;

	return (
		<label className={cn(styles.check, className)}>
			<input
				{...restProps}
				className={styles.check__input}
				type='radio'
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<span className={cn(styles.check__box, disabled ? styles.check__box_disabled : '')}></span>
			{children}
		</label>
	);
};
