import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Icon, Size, Theme } from '../base';
import { getLessonComments, likeComment, sendComment, unlikeComment } from 'actions/comments';
import { useRequest } from 'utils/hooks/useRequest';
import { Comment, CommentsList } from 'models/comment.interface';
import { Roles } from 'enums/roles.enum';

interface Props {
	lesson_id: number;
	role: Roles;
	className?: string;
}

export const LessonComments = (props: Props) => {
	const { lesson_id, role, className, ...restProps } = props;

	const user = useSelector((state: any) => state.user.data);

	const [comments, setComments] = useState<Comment[]>([]);

	const [getLessonCommentsHandle, { loading }] = useRequest<CommentsList>(
		() => getLessonComments(lesson_id, user?.role),
		(data: CommentsList) =>  setComments(data?.results)
	);

	useEffect(() => {
		lesson_id && getLessonCommentsHandle();
	}, [lesson_id]);

	const toggleLike = (commentId: number) => {
		const comment = comments?.find(item => item?.id === commentId);
		if (!comment?.i_like) {
			likeComment(commentId, user?.role).then((result) => {
				setComments([
					...comments.map(item => item?.id === commentId ?
						{ ...comment, i_like: true, likes: result?.likes } as Comment : item)
				]);
			});
		} else {
			unlikeComment(commentId, user?.role).then((result) => {
				setComments([
					...comments.map(item => item?.id === commentId ?
						{ ...comment, i_like: false, likes: result?.likes } as Comment : item)
				]);
			});
		}
	}

	return (
		<section
			{...restProps}
			className={cn(styles.comments, className)}
		>
			<h3>Комментарии к уроку</h3>
			<Formik
				initialValues={{
					message: ''
				}}
				onSubmit={(
					values,
					{ resetForm }
				) => {
					sendComment(lesson_id, user?.role, values.message).then((result) => {
						setComments([{ ...result, likes: 0 }, ...comments]);
						resetForm();
					});
				}}
			>
				{({ values, setFieldValue, isValid }) => (
					<Form className={styles.textarea_wrapper} noValidate>
						<TextareaAutosize
							placeholder='Написать комментарий'
							className={styles.textarea}
							value={values.message}
							onChange={event => setFieldValue('message', event.target.value)}
						/>
						<Button
							theme={Theme.PRIMARY}
							size={Size.S}
							type='submit'
							className={styles.textarea__button}
							disabled={!isValid}
						>
							Отправить
						</Button>
					</Form>
				)}
			</Formik>
			<section className={styles.comments__list}>
				{
					comments?.map((item, index) => (
						<div className={styles.item} key={index}>
							{
								!!item?.user?.avatar ?
									<img
										src={item?.user?.avatar}
										className={styles.avatar}
										alt='аватарка'
									/> :
									<div className={styles.avatar}></div>
							}
							<div className={styles.content}>
								<h5>
									{item?.user?.first_name} {item?.user?.last_name}
								</h5>
								<p className={styles.content__text}>{item?.text}</p>
								<div className={styles.content__like}>
									<span>{item?.likes}</span>
									<button
										onClick={() => toggleLike(item?.id!)}
										disabled={role === Roles.CURATOR}
									>
										<Icon
											icon={ item?.i_like ? 'heart-active' : 'heart'}
											width={12}
											height={13}
										/>
									</button>
								</div>
							</div>
						</div>
					))
				}
			</section>
		</section>
	);
};
