import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';

interface Props {
	url: string;
	className?: string;
}

export const VideoPlayer = (props: Props) => {
	const { url, className } = props;

	const [source, setSource] = useState<any>(null);

	useEffect(() => {
		setSource(
			{
				type: 'video',
				sources: [
					{
						src: url
					}
				]
			}
		);
	}, [url]);

	return (
		<div className={cn(className)}>
			<Plyr
				source={source}
			/>
		</div>
	);
};
