import React, { useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ProgressBar, Size, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';
import { VideoPlayer } from 'components/base';
import { removeLessonFile, uploadLessonFile } from 'actions/lesson';

interface Props {
	id: number;
	lessonId: string;
	video: { id: number, file: string, name: string };
	updateBlock?: (id: number, value: { id: number }, file: { file: string, id: number, name: string }) => void;
	className?: string;
}

export const VideoBlock = (props: Props) => {
	const { id, lessonId, video, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const [isLoading, setIsLoading] = useState(false);
	const [loadingPercent, setLoadingPercent] = useState(0);

	const uploadVideo = () => {
		const input = document.createElement('input');
		input.accept = 'video/mp4,video/x-m4v,video/*';
		input.type = 'file';

		const config = {
			onUploadProgress: (progressEvent: ProgressEvent) => {
				setLoadingPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			}
		}

		input.onchange = e => {
			setIsLoading(true);
			uploadLessonFile(lessonId, (e.target as any).files[0], config).then((result) => {
				setIsLoading(false);
				updateBlock(id, { id: result?.id }, result);
				dispatch(setCurrentEditBlock(null));
			}, () => {
				setIsLoading(false);
			});
		}

		input.click();
	}

	const updateVideo = () => {
		removeLessonFile(video?.id).then(() => {
			uploadVideo();
		});
	}

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
	}

	return (
		<>
			{
				currentEditBlock === id ? (
					<div className={cn(styles.container, className)}>
						<div className={styles.content}>
							{
								isLoading && <ProgressBar percent={loadingPercent} />
							}
							<div className={styles.controls}>
								<Button
									theme={Theme.SECONDARY}
									size={Size.S}
									onClick={cancel}
									className={styles.button}
								>
									Отменить
								</Button>
								<Button
									theme={Theme.PRIMARY}
									size={Size.S}
									onClick={!!video ? updateVideo : uploadVideo}
									className={styles.button}
								>
									{ !!video ? 'Обновить видео' : 'Загрузить видео' }
								</Button>
							</div>
						</div>
					</div>
				) : (
					<VideoPlayer url={video?.file} />
				)
			}
		</>
	);
};
