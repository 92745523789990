import React from 'react';
import styles from './index.module.sass';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Size, TextEditor, TextInput, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';
import * as Yup from 'yup';

interface Props {
	id: number;
	index: number;
	value: {
		text: string,
		max_score: number
	};
	updateBlock?: (id: number, value: { text: string, max_score: number }) => void;
	className?: string;
}

const TextTaskBlockSchema = Yup.object().shape({
	text: Yup.string()
		.required('Заполните поле'),
	max_score: Yup.number()
		.required('Заполните поле')
});

export const TextTaskBlock = (props: Props) => {
	const { id, index, value, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
	}

	return (
		<>
			<h3 className={styles.task__caption}>Задание № {index + 1}</h3>
			{
				currentEditBlock === id ? (
					<Formik
						initialValues={{
							text: value?.text ? value?.text : '',
							max_score: value?.max_score ? value?.max_score: 0
						}}
						validationSchema={TextTaskBlockSchema}
						onSubmit={(
							values
						) => {
							updateBlock(id, values);
							dispatch(setCurrentEditBlock(null));
						}}
						enableReinitialize={true}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form className={styles.form} noValidate>
								<div className={className}>
									<TextEditor
										value={values.text}
										onChange={(value) => setFieldValue('text', value)}
									/>
									<div className={styles.field}>
										<span>Максимальное количество баллов</span>
										<TextInput
											value={values.max_score.toString()}
											type='number'
											onChange={event => setFieldValue('max_score', +event.target.value)}
											className={styles.field_score}
										/>
									</div>
									<div className={styles.controls}>
										<Button
											theme={Theme.SECONDARY}
											size={Size.S}
											onClick={cancel}
											className={styles.button}
										>
											Отменить
										</Button>
										<Button
											type='submit'
											theme={Theme.PRIMARY}
											size={Size.S}
											className={styles.button}
											disabled={!isValid}
										>
											Сохранить
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<>
						{
							value?.text &&
							<p
								dangerouslySetInnerHTML={{ __html: value?.text }}
								className={styles.task__text}
							></p>
						}
						<TextEditor
							value={''}
							onChange={(value) => {}}
							disabled
						/>
						<section className={styles.task__controls}>
							<Button
								theme={Theme.SECONDARY}
								size={Size.S}
								className={styles.task__controls__button}
								disabled
							>
								Прикрепить файл
							</Button>
						</section>
					</>
				)
			}
		</>
	);
};
