import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Icon } from 'components/base';
import { getCourseModules } from 'actions/courses';
import { Module } from 'models/module.interface';
import { numberEnding } from 'utils/helpers/number-ending';
import { useRequest } from 'utils/hooks/useRequest';
import { Roles } from 'enums/roles.enum';
import { setCourse } from 'reducers/courseSlice';

export const CourseModules = () => {
	const { id } = useParams();
	const user = useSelector((state: any) => state.user.data);
	const dispatch = useDispatch();

	const [data, setModule] = useState<Module>();

	const [getCourseModulesHandle, { loading }] = useRequest<Module>(
		(id, role) => getCourseModules(+id, role),
		(data: Module) => {
			setModule(data);
			dispatch(setCourse({
				...data?.course,
				tariff: data?.title,
				total_score: data?.total_score,
				max_score: data?.max_score
			}));
		}
	);

	useEffect(() => {
		id && getCourseModulesHandle(+id, user?.role);
	}, []);

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<section className={styles.modules}>
				{
					data?.modules?.map((module, index) => (
						<div
							key={index}
							className={cn(
								styles.modules__item,
								!module?.available && user?.role === Roles.STUDENT ? styles.modules__item_disabled : ''
							)}
						>
							<div
								className={cn(
									styles.caption,
									!module?.available && user?.role === Roles.STUDENT ? styles.caption_disabled : ''
								)}
							>
								<span className={styles.lessons}>
									{`${module?.lessons_count} ${numberEnding(module?.lessons_count, ['урок', 'урока', 'уроков'])}`}
								</span>
								<h3>{module?.title}</h3>
							</div>
							<NavLink
								to={`modules/${module?.id}`}
							>
								<button className={styles.button}>
									<Icon
										icon={!module?.available && user?.role === Roles.STUDENT ? 'lock' : 'chevron'}
										size={20}
									/>
								</button>
							</NavLink>
						</div>
					))
				}
			</section>
		);
	}, [data, loading])

	return <>{content}</>;
};
