import React, { ChangeEvent, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import cn from 'classnames';
import styles from './index.module.sass';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Radio, Size } from 'components/base';
import { LessonTaskType } from 'enums/lesson-task-type.enum';
import { sendTaskSolution, updateTaskSolution } from 'actions/homework';
import { HomeWorkSolution } from 'models/lesson.interface';
import { HomeworkStatus } from 'enums/homework-status.enum';
import { Roles } from 'enums/roles.enum';
import { TextTask } from './components/TextTask';
import { removeNull } from 'utils/helpers/remove-null';

interface Props {
	homework_id: number;
	tasks: any[];
	updateHomeWorkSolution?: (solution: HomeWorkSolution) => void;
	solution?: HomeWorkSolution;
	disabledTextTasks?: boolean;
	disabled?: boolean;
	className?: string;
}

export const StudentHomework = (props: Props) => {
	const {
		homework_id,
		tasks,
		updateHomeWorkSolution = () => {},
		solution,
		disabledTextTasks = false,
		disabled = false,
		className,
		...restProps
	} = props;

	const user = useSelector((state: any) => state.user.data);

	const [isHomeworkSanding, setIsHomeworkSanding] = useState(false);

	const getHomeWorkTask = (task: any, value: { text: string; file_id: string; } | number | number[], fieldName: string, setFieldValue: Function) => {
		const handleCheckOption = (e: ChangeEvent<HTMLInputElement>) => {
			const { checked, name } = e.target;

			if (!Array.isArray(value)) {
				return;
			}

			if (checked) {
				setFieldValue(fieldName, [...value, +name]);
			} else {
				setFieldValue(
					fieldName,
					value?.filter((item: number) => item !== +name)
				);
			}
		}

		const getTestAnswerColor = (taskId: number, answerId: number) => {
			task = solution?.content?.find(item => item?.id === taskId);

			if (!task) {
				return;
			}

			if (Array.isArray(task?.answer)) {
				const answer = task?.answer?.find((item: any) => item?.id === answerId);
				if (answer?.is_correct) {
					return '#5abd0d';
				}

				if (answer?.selected && !answer?.is_correct) {
					return '#d86d8f';
				}

				return;
			}

			if (task?.answer?.id === answerId) {
				return task?.answer?.is_correct ? '#5abd0d' : '#d86d8f';
			} else {
				return task?.answer?.is_correct ? '': '#5abd0d';
			}

			return '';
		}

		const getTaskComment = (taskId: number) => {
			return solution?.content?.find(item => item?.id === taskId)?.comment;
		}

		switch (task?.type) {
			case LessonTaskType.TEXT_TASK:
				return (
					<TextTask
						question={task?.text}
						value={(value as { text: string; file_id: string })?.text}
						onChange={(value) => setFieldValue(`${fieldName}.text`, value)}
						fileId={(value as { text: string; file_id: string })?.file_id}
						onFileUpload={(fileId) => setFieldValue(`${fieldName}.file_id`, fileId)}
						onDeleteFile={() => setFieldValue(`${fieldName}.file_id`, null)}
						comment={getTaskComment(task?.id)}
						disabled={disabledTextTasks}
					/>
				);

				break;

			case LessonTaskType.TEST_TASK:
				return (
					<>
						<p
							dangerouslySetInnerHTML={{ __html: task?.text }}
							className={styles.block__text}
						></p>
						{
							task?.options?.map((option: { id: number, value: string }, index: number) => (
								<Radio
									key={index}
									value={option?.id}
									checked={value === option?.id}
									onChange={() => setFieldValue(fieldName, option?.id)}
									disabled={disabled}
									className={styles.block__option}
								>
									<p
										style={{ color: getTestAnswerColor(task?.id, option?.id) }}
										dangerouslySetInnerHTML={{ __html: option?.value }}
									></p>
								</Radio>
							))
						}
					</>
				);

				break;

			case LessonTaskType.MULTI_TEST_TASK:
				return (
					<>
						<p
							dangerouslySetInnerHTML={{ __html: task?.text }}
							className={styles.block__text}
						></p>
						{
							Array.isArray(value) &&
							task?.options?.map((option: { id: number, value: string }, index: number) => (
								<Checkbox
									key={index}
									value={value?.includes(option?.id)}
									name={option?.id?.toString()}
									onChange={handleCheckOption}
									disabled={disabled}
									className={styles.block__option}
								>
									<p
										style={{ color: getTestAnswerColor(task?.id, option?.id) }}
										dangerouslySetInnerHTML={{ __html: option?.value }}
									></p>
								</Checkbox>
							))
						}
					</>
				);

				break;
		}
	}

	const isFormDisabled = (answers: Array<string | number | number[] | undefined>) => {
		return answers?.length !== tasks?.length ||
			answers?.includes(undefined) ||
			!!answers.some(answer =>
				answer === '' ||
				(Array.isArray(answer) && answer?.length === 0)
			);
	}

	const getFormatedAnswer = (answer: string | number | number[] | { text: string; file_id: string }) => {
		switch (typeof answer) {
			case 'object':
				if (Array.isArray(answer)) {
					return answer?.map(item => {
						return { id: item }
					});
				} else {
					return removeNull(answer);
				}
				break;

			case 'number':
				return { id: answer };
				break;
		}
	}

	const getFormatedSolution = (solution: any[] | undefined) => {
		if (!solution) {
			return [];
		}

		return solution?.map(item => {
			switch (item?.type) {
				case LessonTaskType.TEXT_TASK:
					return item?.answer;
					break;

				case LessonTaskType.TEST_TASK:
					return item?.answer?.id;
					break;

				case LessonTaskType.MULTI_TEST_TASK:
					return item?.answer
						?.filter((answer: any) => answer.selected)
						?.map((answer: any) => answer?.id);
					break;
			}
		});
	}

	const getSolutionScore = (taskId: number) => {
		return solution?.content?.find(item => item?.id === taskId)?.score;
	}

	return (
		<Formik
			initialValues={{
				answers: getFormatedSolution(solution?.content)
			}}
			onSubmit={(
				values
			) => {
				setIsHomeworkSanding(true);

				const content = values.answers?.map((answer, index) => {
					return {
						id: tasks[index]?.id,
						type: tasks[index]?.type,
						answer: getFormatedAnswer(answer)
					}
				});

				if (solution?.id) {
					updateTaskSolution(solution?.id, content).then((result) => {
						updateHomeWorkSolution(result);
						toast.success('Ответ отправлен', {
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							theme: 'dark',
							bodyStyle: { fontFamily: 'Circe' }
						});
					}, () => {
						toast.error('Не удалось отправить ответ', {
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							theme: 'dark',
							bodyStyle: { fontFamily: 'Circe' }
						});
					}).finally(() => setIsHomeworkSanding(false));
				} else {
					sendTaskSolution(homework_id, content).then((result) => {
						updateHomeWorkSolution(result);
						toast.success('Ответ отправлен', {
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							theme: 'dark',
							bodyStyle: { fontFamily: 'Circe' }
						});
					}, () => {
						toast.error('Не удалось отправить ответ', {
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							theme: 'dark',
							bodyStyle: { fontFamily: 'Circe' }
						});
					}).finally(() => setIsHomeworkSanding(false));
				}
			}}
			{...restProps}
		>
			{({ values, setFieldValue, isValid }) => (
				<Form className={cn(styles.form, className)} noValidate>
					<FieldArray
						name='answers'
						render={() => (
							<>
								{
									tasks?.map((task, index) => (
										<div className={styles.block} key={index}>
											<div className={styles.block__caption}>
												<h3>Задание № {index + 1}</h3>
												{
													(
														(task?.type !== LessonTaskType.TEXT_TASK && !!solution?.status) ||
														(task?.type === LessonTaskType.TEXT_TASK && solution?.status === HomeworkStatus.VERIFIED) ||
														user?.role === Roles.CURATOR
													) &&
													<span className={styles.block__caption__score}>
														{
															getSolutionScore(task?.id) !== undefined &&
															<>{getSolutionScore(task?.id)} из </>
														}
															{task?.max_score}
													</span>
												}
											</div>
											{
												getHomeWorkTask(
													task,
													values?.answers && values?.answers[index] ? values.answers[index] : [],
													`answers.${index}`,
													setFieldValue
												)
											}
										</div>
									))
								}
							</>
						)}
					/>
					{
						(!disabled || !disabledTextTasks) &&
						<div className={styles.block__controls}>
							<Button
								size={Size.M}
								type='submit'
								isLoading={isHomeworkSanding}
								disabled={values.answers && isFormDisabled(values.answers)}
							>
								Отправить ответ
							</Button>
						</div>
					}
				</Form>
			)}
		</Formik>
	);
};

