import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import styles from './index.module.sass';
import { useSelector } from 'react-redux';
import { Roles } from 'enums/roles.enum';
import { Icon } from 'components/base';
import { numberEnding } from 'utils/helpers/number-ending';
import { formatDate } from 'utils/helpers/date-formater';

export const CourseLayout = () => {
	const location = useLocation();

	const user = useSelector((state: any) => state.user.data);
	const course = useSelector((state: any) => state.course.data);

	const getUrl = (role: Roles) => {
		switch (role) {
			case Roles.STUDENT:
				return '/courses';
				break;

			case Roles.METHODIST:
				return '/methodist-courses';
				break;

			case Roles.CURATOR:
				return '/curator-courses';
				break;
		}
	}

	return (
		<div className={styles.content}>
			<section className={styles.cover}>
				<div className={styles.cover__content}>
					<div className={styles.wrapper}>
						<NavLink
							to={getUrl(user?.role)}
							className={styles.link}
						>
							<span>Курсы</span>
							<Icon icon='arrow' size={10} />
						</NavLink>
						{
							location.pathname.split('/')[3] === 'modules' &&
							<NavLink
								to={`${getUrl(user?.role)}/${location.pathname.split('/')[2]}`}
								className={styles.link}
							>
								<span>{course?.title}</span>
								<Icon icon='arrow' size={10} />
							</NavLink>
						}
					</div>
					<h2>{course?.title}</h2>
				</div>
			</section>
			<section className={styles.info}>
				{
					!!course?.tariff &&
					<>
						<div className={styles.info__item}>
							<div>
								<span className={styles.caption}>Тариф</span>
								<h4>{course?.tariff}</h4>
							</div>
							{
								user?.role === Roles.STUDENT &&
								<div>
									<span className={styles.caption}>Общий балл</span>
									<h4>
										{`${course?.total_score} / ${course?.max_score}`}
									</h4>
								</div>
							}
						</div>
						<div className={styles.info__item}>
							<div>
								<span className={styles.caption}>Начало курса</span>
								<h4>{formatDate(course?.start_date, 'dd.LL')}</h4>
							</div>
							<div>
								<span className={styles.caption}>Окончание курса</span>
								<h4>
									{formatDate(course?.end_date, 'dd.LL')}
								</h4>
							</div>
						</div>
					</>
				}
				{
					course?.lessons_count && course?.lessons_duration &&
					<div className={styles.info__item}>
						<div>
							<span className={styles.caption}>Уроков</span>
							<h4>{course?.lessons_count}</h4>
						</div>
						<div>
							<span className={styles.caption}>Время прохождения</span>
							<h4>
								{
									`≈ ${Math.floor(course?.lessons_duration / 60)} 
									${numberEnding(Math.floor(course?.lessons_duration / 60), ['час', 'часа', 'часов'])} 
									${course?.lessons_duration % 60} мин.`
								}
							</h4>
						</div>
					</div>
				}
			</section>
			<Outlet />
		</div>
	);
};
