import React, { useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';

interface Props {
	title: string;
	text: string;
	className?: string;
}

export const Accordion = (props: Props) => {
	const { title, text, className, ...restProps } = props;

	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	return (
		<section className={cn(styles.accordion, className)} {...restProps}>
			<button onClick={toggle}>
				<h3>{title}</h3>
			</button>
			<div className={styles.accordion__content} aria-expanded={!isOpen}>
				{text}
			</div>
		</section>
	);
};

