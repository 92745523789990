import React, { FC } from 'react';
import cn from 'classnames';
import styles from './index.module.sass';
import { Spin } from '../Spin';

interface Props {
	onClick?: () => void;
	theme?: Theme;
	size?: Size;
	stretch?: boolean;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
	isLoading?: boolean;
	className?: string;
}

export enum Theme {
	PRIMARY,
	SECONDARY,
	THIRD
}

export enum Size {
	S,
	M,
	L
}

export const Button: FC<Props> = (props) => {
	const {
		onClick,
		theme = Theme.PRIMARY,
		size = Size.M,
		stretch = false,
		disabled = false,
		type = 'button',
		isLoading = false,
		className,
		children,
		...restProps
	} = props;

	return (
		<button
			{...restProps}
			onClick={onClick}
			className={cn([
				{
					[styles.btn]: true,
					[styles.btn_primary]: theme === Theme.PRIMARY,
					[styles.btn_secondary]: theme === Theme.SECONDARY,
					[styles.btn_third]: theme === Theme.THIRD,
					[styles.btn_size_s]: size === Size.S,
					[styles.btn_size_m]: size === Size.M,
					[styles.btn_size_l]: size === Size.L,
					[styles.btn_stretch]: stretch,
					[styles.btn_disabled]: disabled,
				},
				className
			])}
			type={type}
			disabled={disabled}
		>
			{ isLoading ? <Spin /> : children }
		</button>
	);
}
