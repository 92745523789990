import { safelyGet } from 'services/request';
import { CoursesList, StaffCoursesList } from 'models/course.interface';
import { Module } from 'models/module.interface';
import { Roles } from 'enums/roles.enum';

export const getActualStudentCourses = async() => {
	const result = await safelyGet<CoursesList>('education/student/tariff/');
	return result.response;
}

export const getStaffCourses = async(role: Roles) => {
	const result = await safelyGet<StaffCoursesList>(`education/${role}/course/`);
	return result.response;
}

export const getCompletedStudentCourses = async() => {
	const result = await safelyGet<CoursesList>('education/student/completed-tariff/');
	return result.response;
}

export const getCourseModules = async (id: number, role: Roles) => {
	const result = await safelyGet<Module>(`education/${role}/tariff/${id}/modules/`);
	return result.response;
}

export const getModuleLessons = async (id: number, role: Roles) => {
	const result = await safelyGet<any>(`education/${role}/module/${id}/lessons/`);
	return result.response;
}
