import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { initAuthInterceptor } from './interceptors';
import { StorageService } from './storageService';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const storage = StorageService.getInstance();

initAuthInterceptor();

axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401 || error.response.status === 403) {
		storage.logout();
	}

	return Promise.reject(error);
});

type SuccessResponse<T> = { response: T, headers: any };
export type RequestError<T> = { response?: any; message: any; error?: T; status: any; request?: any };

const handleSuccess = <T>(response: AxiosResponse<T>): SuccessResponse<T> => {
	return { response: response?.data, headers: response?.headers };
};

const handleError = <T>(error: RequestError<T>) => {
	throw error;
};

const tryCatchWrapper = <T>(request: Promise<AxiosResponse<T>>) =>
	request.then(handleSuccess).catch(handleError);

export const safelyGet = <T>(
	path: string,
	config?: AxiosRequestConfig,
): Promise<SuccessResponse<T>> => {
	return tryCatchWrapper<T>(
		axios.get<T>(path, {
			...config
		})
	);
};

export const safelyPost = <T>(
	path: string,
	body: any,
	config?: AxiosRequestConfig
): Promise<SuccessResponse<T>> => {
	return tryCatchWrapper<T>(
		axios.post<T>(path, body, {
			...config
		})
	);
};

export const safelyPut = <T>(
	path: string,
	body: any,
	config?: AxiosRequestConfig
): Promise<SuccessResponse<T>> => {
	return tryCatchWrapper<T>(
		axios.put<T>(path, body, {
			...config
		})
	);
};

export const safelyPatch = <T>(
	path: string,
	body: any,
	config?: AxiosRequestConfig
): Promise<SuccessResponse<T>> => {
	return tryCatchWrapper<T>(
		axios.patch<T>(path, body, {
			...config
		})
	);
};

export const safelyDelete = <T>(
	path: string,
	config?: AxiosRequestConfig
): Promise<SuccessResponse<T>> => {
	return tryCatchWrapper<T>(
		axios.delete<T>(path, {
			...config
		})
	);
};
