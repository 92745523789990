import { safelyDelete, safelyGet, safelyPost } from 'services/request';
import { Roles } from 'enums/roles.enum';
import { Comment, CommentsList } from 'models/comment.interface';

export const sendComment = async(lessonId: number, role: Roles, text: string) => {
	const result = await safelyPost<Comment>(`/education/${role}/lesson/${lessonId}/comment/`, { text });
	return result.response;
}

export const getLessonComments = async(lessonId: number, role: Roles) => {
	const result = await safelyGet<CommentsList>(`/education/${role}/lesson/${lessonId}/comment/`);
	return result.response;
}

export const likeComment = async(commentId: number, role: Roles) => {
	const result = await safelyPost<Comment>(`/education/${role}/lesson-comment/${commentId}/like/`, {});
	return result.response;
}

export const unlikeComment = async(commentId: number, role: Roles) => {
	const result = await safelyDelete<Comment>(`/education/${role}/lesson-comment/${commentId}/like/`, {});
	return result.response;
}
