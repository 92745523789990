import React from 'react';
import styles from './index.module.sass';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { Button, Checkbox, Icon, Size, TextEditor, TextInput, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';

interface Props {
	id: number;
	index: number;
	value: {
		text: string,
		options: Array<{ id: number, value: string, score: number }>
	};
	updateBlock?: (id: number, value: { text: string, options: Array<{ id: number, value: string, score: number }> }) => void;
	className?: string;
}

const MultiTestTaskBlockSchema = Yup.object().shape({
	text: Yup.string()
		.required('Заполните поле'),
	options: Yup.array()
		.of(
			Yup.object().shape({
				value: Yup.string()
					.required('Заполните поле'),
				score: Yup.number()
					.required('Заполните поле')
			})
		)
});

export const MultiTestTaskBlock = (props: Props) => {
	const { id, index, value, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
	}

	return (
		<>
			<h3 className={styles.task__caption}>Задание № {index + 1}</h3>
			{
				currentEditBlock === id ? (
					<Formik
						initialValues={{
							text: value?.text ? value?.text : '',
							options: value?.options ? value?.options : [{ id: 1, value: '', score: 0 }]
						}}
						validationSchema={MultiTestTaskBlockSchema}
						onSubmit={(
							values
						) => {
							const data = {
								text: values.text,
								max_score: values.options.reduce((sum, item) => {
									if (+item.score > 0) {
										return +sum + item.score;
									} else {
										return +sum;
									}
								}, 0),
								options: values.options.map(item => {
									return { ...item, score: +item.score }
								})
							};

							updateBlock(id, data);
							dispatch(setCurrentEditBlock(null));
						}}
						enableReinitialize={true}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form className={styles.form} noValidate>
								<div className={className}>
									<TextEditor
										value={values.text}
										onChange={(value) => setFieldValue('text', value)}
									/>
									<FieldArray
										name='options'
										render={optionsArray => (
											<>
												{values.options.map((option, index) => (
													<div key={index} className={styles.option}>
														<TextEditor
															value={option.value}
															onChange={(value) => setFieldValue(`options.${index}.value`, value)}
														/>
														<TextInput
															value={option.score}
															type='number'
															onChange={event => setFieldValue(`options.${index}.score`, event.target.value)}
															className={
																cn(
																	styles.option__score,
																	index === 0 ? styles.option__score_first : ''
																)
															}
														/>
														{
															index !== 0 &&
															<button
																onClick={() => optionsArray.remove(index)}
																className={styles.option__control_delete}
															>
																<Icon icon='close' size={18} />
															</button>
														}
													</div>
												))}
												<Button
													theme={Theme.SECONDARY}
													size={Size.S}
													onClick={() => optionsArray.push({ id: values.options.length + 1, value: '', score: 0 })}
													className={styles.option__control}
												>
													Добавить вариант ответа
												</Button>
											</>
										)}
									/>
									<div className={styles.controls}>
										<Button
											theme={Theme.SECONDARY}
											size={Size.S}
											onClick={cancel}
											className={styles.button}
										>
											Отменить
										</Button>
										<Button
											type='submit'
											theme={Theme.PRIMARY}
											size={Size.S}
											className={styles.button}
											disabled={!isValid}
										>
											Сохранить
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<>
						{
							value?.text &&
							<p
								dangerouslySetInnerHTML={{ __html: value?.text }}
								className={styles.task__text}
							></p>
						}
						{
							value?.options?.map((option, index) => (
								<div key={index} className={styles.task__option}>
									<Checkbox
										value={false}
										onChange={() => {}}
										disabled
									/>
									<p dangerouslySetInnerHTML={{ __html: option?.value }}></p>
								</div>
							))
						}
					</>
				)
			}
		</>
	);
};
