import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.sass';
import { Tariff } from 'models/course.interface';

interface Props {
	tariff: Tariff;
	className?: string;
}

export const TariffCard = (props: Props) => {
	const {
		tariff,
		className,
		...restProps
	} = props;

	return (
		<div
			{...restProps}
			className={cn(styles.card, className)}
			style={{ background: tariff?.color ? tariff?.color : '#eef6f8' }}
		>
			<NavLink to={`${tariff?.id}`} className={styles.link}>
				<h3>{tariff?.title}</h3>
				<p className={styles.card__description}>{tariff?.description}</p>
			</NavLink>
		</div>
	);
};
