import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import { Button, Size, TextEditor, TextInput, Theme } from 'components/base';
import { downLoadDocument, getStudentAnswerFile } from 'actions/lesson';
import { File } from 'models/lesson.interface';

interface Props {
	question: string;
	answer: { text: string; file_id?: string };
	comment: string | undefined;
	onCommentChange: (value: string) => void;
	score: string;
	onScoreChange: (score: number) => void;
	disabled?: boolean;
}

export const CuratorTextTask = (props: Props) => {
	const { question, answer, comment, onCommentChange, score, onScoreChange, disabled = false } = props;

	const [file, setFile] = useState<File>();

	useEffect(() => {
		if (answer?.file_id) {
			getStudentAnswerFile(answer?.file_id).then((result: File) => {
				setFile(result);
			});
		}
	}, [answer?.file_id]);

	const downLoadDocumentHandle = () => {
		downLoadDocument(file?.file!, file?.name!).then(() => {
		});
	}

	return (
		<>
			<p
				dangerouslySetInnerHTML={{ __html: question }}
				className={styles.block__text}
			></p>
			<h4>Ответ</h4>
			<p
				className={styles.block__answer}
				dangerouslySetInnerHTML={{ __html: answer.text }}
			></p>
			{
				answer?.file_id &&
				<div className={styles.block__answer__file}>
					<span>{file?.name}</span>
					<Button
						theme={Theme.THIRD}
						size={Size.S}
						className={styles.block__answer__file__button}
						onClick={downLoadDocumentHandle}
					>
						Скачать
					</Button>
				</div>
			}
			<h4>Комментарий</h4>
			<TextEditor
				value={comment || ''}
				onChange={onCommentChange}
				disabled={disabled}
			/>
			<div className={styles.score}>
				<span>Балл</span>
				<TextInput
					value={score.toString()}
					type='number'
					onChange={(event) => onScoreChange(+event.target.value)}
					className={styles.score__value}
					disabled={disabled}
				/>
			</div>
		</>
	);
};

