import { combineReducers } from '@reduxjs/toolkit';
import userSlice from 'reducers/userSlice';
import currentEditBlockSlice from 'reducers/currentEditBlockSlice';
import courseSlice from 'reducers/courseSlice';

export const rootReducer = combineReducers({
	user: userSlice,
	currentEditBlock: currentEditBlockSlice,
	course: courseSlice
});
