import React, { useRef, useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import useOnClickOutside from 'utils/hooks/clickOutside';
import { Icon } from '../Icon';

interface Props<T> {
	value: T;
	options: Array<{ value: T, label: string }>;
	onChange: (value: T) => void;
	className?: string;
}

export const Select = <T,>(props: Props<T>) => {
	const { value, options, onChange, className, ...restProps } = props;

	const select = useRef<HTMLDivElement>(null);

	const [isOpen, setIsOpen] = useState(false);

	useOnClickOutside(select, () => {
		setIsOpen(false);
	});

	const toggle = () => {
		setIsOpen(!isOpen);
	}

	return (
		<div ref={select} className={cn(styles.select, className)} {...restProps}>
			<button onClick={toggle} className={styles.select__control}>
				{options?.find(item => item.value === value)?.label}
				<Icon
					icon='chevron'
					size={16}
					rotateDegrees={90}
					className={styles.select__control__icon}
				/>
			</button>
			{
				isOpen &&
				<div className={styles.popup}>
					<ul>
						{
							options?.map((item, index) => (
								<li key={index}>
									<button onClick={() => { onChange(item.value); setIsOpen(false); }}>{item?.label}</button>
								</li>
							))
						}
					</ul>
				</div>
			}
		</div>
	);
};

