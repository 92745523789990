import React from 'react';
import cn from 'classnames';
import styles from './index.module.sass';
import { Icon } from 'components/base';

interface Props {
	className?: string;
}

export const Footer = (props: Props) => {
	const { className, ...restProps } = props;
	return (
		<footer {...restProps} className={cn(styles.container, className)}>
			<section className={styles.contacts}>
				<div className={styles.contacts__item}>
					<span className={styles.contacts__company}>ООО «Селлер хелп»</span>
					<address className={styles.contacts__info}>
						Казань, Спартаковская 88Б, офис 1025
					</address>
				</div>
				<div className={styles.contacts__tax}>
					<span className={styles.contacts__info}>ИНН 1644059950</span>
					<span className={styles.contacts__info}>ОГРН 1101644002514</span>
				</div>
			</section>
			<section className={styles.contacts__links}>
				<a href='https://vk.com/sellerhelp' target='_blank' rel='noopener noreferrer'>
					<Icon icon='vk' size={24} className={styles.contacts__links__icon} />
				</a>
			</section>
		</footer>
	);
};
