import React, { useRef, useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { Button, Theme } from 'components/base';
import useOnClickOutside from 'utils/hooks/clickOutside';

interface Props<T> {
	menuLabel: string;
	options: Array<{ label: string, value: T }>;
	onSelect: (value: T) => void;
	className?: string;
}

export const LessonMenu = <T,>(props: Props<T>) => {
	const { menuLabel, options, onSelect, className } = props;

	const menu = useRef<HTMLDivElement>(null);

	const [menuIsMenuOpen, setMenuOpen] = useState(false);

	useOnClickOutside(menu, () => {
		setMenuOpen(false);
	});

	const onSelectHandle = (value: T) => {
		setMenuOpen(false);
		onSelect(value);
	}

	return (
		<div ref={menu} className={cn(styles.menu, className)}>
			<Button
				theme={Theme.PRIMARY}
				onClick={() => setMenuOpen(!menuIsMenuOpen)}
			>
				{menuLabel}
			</Button>
			{
				menuIsMenuOpen &&
				<div className={styles.popup}>
					{
						options?.map((item, index) => (
							<button
								key={index}
								onClick={() => onSelectHandle(item?.value)}
							>
								{item?.label}
							</button>
						))
					}
				</div>
			}
		</div>
	);
};
