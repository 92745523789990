import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { MobileView } from 'react-device-detect';
import { getStaffCourses } from 'actions/courses';
import { StaffCourse } from 'models/course.interface';
import { formatDate } from 'utils/helpers/date-formater';
import { TariffCard } from './TariffCard';
import { setCourse } from 'reducers/courseSlice';
import { useDispatch, useSelector } from 'react-redux';

export const StaffCourses = () => {
	const dispatch = useDispatch();
	const user = useSelector((state: any) => state.user.data);

	const [loading, setLoading] = useState(true);
	const [courses, setCourses] = useState<StaffCourse[]>([]);

	useEffect(() => {
		dispatch(setCourse(null));
		getStaffCourses(user?.role).then((response) => {
			setCourses(response?.results);
			setLoading(false);
		});
	}, []);

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<div className={styles.container}>
				<MobileView>
					<p className={styles.caption}>Мои курсы</p>
				</MobileView>
				{
					courses?.length > 0 &&
					<section>
						<h1>Курсы</h1>
						{
							courses?.map((item, index) => (
								<section className={styles.union} key={index}>
									<div className={styles.course}>
										<h3>{item?.title}</h3>
										<p className={styles.course__description}>
											{item?.description}
										</p>
										<div className={styles.course__date}>
											<span className={styles.course__date_title}>
												Начало курса
											</span>
											<h4>
												{formatDate(item?.start_date, 'dd.LL')}
											</h4>
										</div>
										<div className={styles.course__date}>
											<span className={styles.course__date_title}>
												Окончание курса
											</span>
											<h4>
												{formatDate(item?.end_date, 'dd.LL')}
											</h4>
										</div>
									</div>
									<div className={styles.list}>
										{
											item?.tariffs?.map((tariff, index) => (
												<TariffCard
													tariff={tariff}
													key={index * 100}
													className={styles.list__item}
												/>
											))
										}
									</div>
								</section>
							))
						}
					</section>
				}
				{
					courses?.length === 0 &&
					<section className={styles.empty}>
						<p className={styles.empty__text}>
							Пока нет ни одного курса
						</p>
					</section>
				}
			</div>
		);
	}, [loading, courses]);

	return <>{content}</>;
};
