import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Roles } from 'enums/roles.enum';

const DEFAULT_URL = '/auth'

export const PrivateStudentRoute: FC = (props) => {
	const {
		children
	} = props;

	const user = useSelector((state: any) => state.user.data);

	return (
		<>
			{
				user?.role !== Roles.STUDENT ? (
					<Navigate
						to={DEFAULT_URL}
					/>
				) : children
			}
		</>
	);
};
