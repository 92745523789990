import React from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { HomeworkStatus } from 'enums/homework-status.enum';

interface Props {
	status: HomeworkStatus;
	score?: number;
	className?: string;
}

export const Status = (props: Props) => {
	const { status, score = null, className } = props;

	const Status = {
		[HomeworkStatus.RESOLVED]: {
			color: '#ffa500',
			text: 'На проверке'
		},
		[HomeworkStatus.RETURNED]: {
			color: '#d86d8f',
			text: 'Требует доработки'
		},
		[HomeworkStatus.VERIFIED]: {
			color: '#5abd0d',
			text: 'Принято'
		},
	};

	return (
		<div
			className={cn(styles.container, className)}
			style={{ backgroundColor: Status[status]?.color }}
		>
			{Status[status]?.text}
			{
				status === HomeworkStatus.VERIFIED && <> ({score})</>
			}
		</div>
	);
}
