import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { StorageService } from 'services/storageService';

const DEFAULT_URL = '/'

export const PublicRoute: FC = (props) => {
	const {
		children
	} = props;

	const storage = StorageService.getInstance();

	return (
		<>
			{
				!!storage.localStorageUser || !!storage.sessionStorageUser ? (
					<Navigate
						to={DEFAULT_URL}
					/>
				) : children
			}
		</>
	);
};
