import React, { useRef } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LessonBlockType } from 'enums/lesson-block-type.enum';
import { Block, DocumentBlock, ImageBlock, LessonMenu, TextBlock, VideoBlock } from 'pages/MethodistLesson/components';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';

const lessonBlockOptions = [
	{
		label:	'Текст',
		value:	LessonBlockType.TEXT
	},
	{
		label:	'Изображение',
		value:	LessonBlockType.IMAGE
	},
	{
		label:	'Видео',
		value:	LessonBlockType.VIDEO
	},
	{
		label:	'Документ',
		value:	LessonBlockType.DOCUMENT
	}
];

interface Props {
	lessonId: string | undefined;
	blocks: any[];
	files: Array<{ file: string, id: number, name: string }>;
	onLessonContentUpdate: (blocks: any[], file?: { file: string, id: number, name: string }) => void;
	maxBlockId: number;
	onUpdateMaxBlockId: () => void;
	className?: string;
}

export const LessonContent = (props: Props) => {
	const {
		lessonId,
		blocks,
		files,
		onLessonContentUpdate,
		maxBlockId,
		onUpdateMaxBlockId,
		className,
		...restProps
	} = props;

	const dispatch = useDispatch();

	const containerBottom = useRef<HTMLDivElement>(null);

	const getFile = (fileId: number) => {
		return files?.find(item => item?.id === fileId);
	}

	const getLessonBlock = (type: LessonBlockType, blockId: number, value: any) => {
		switch (type) {
			case LessonBlockType.TEXT:
				return <TextBlock
					id={blockId}
					value={value}
					updateBlock={updateBlock}
				/>;

				break;

			case LessonBlockType.IMAGE:
				return lessonId && <ImageBlock
					id={blockId}
					lessonId={lessonId}
					image={getFile(value?.id)!}
					updateBlock={updateBlock}
				/>;

				break;

			case LessonBlockType.VIDEO:
				return lessonId && <VideoBlock
					id={blockId}
					lessonId={lessonId}
					video={getFile(value?.id)!}
					updateBlock={updateBlock}
				/>;

				break;

			case LessonBlockType.DOCUMENT:
				return lessonId && <DocumentBlock
					id={blockId}
					lessonId={lessonId}
					file={{ ...value, file: getFile(value?.id)?.file! }}
					updateBlock={updateBlock}
				/>;

				break;
		}
	}

	const addLessonBlock = (type: LessonBlockType) => {

		const updatedBlocks = blocks?.length > 0  ? [
			...blocks,
			{
				type,
				value: null,
				id: maxBlockId + 1
			}
		] : [
			{
				type,
				value: null,
				id: maxBlockId + 1
			}
		];

		onLessonContentUpdate(updatedBlocks);
		onUpdateMaxBlockId();

		dispatch(setCurrentEditBlock(maxBlockId + 1));

		setTimeout(() => {
			window.scrollTo({ top: containerBottom.current?.getBoundingClientRect().height! - 200, behavior: 'smooth' });
		}, 0);
	}

	const moveBlocks = (dragIndex: number, hoverIndex: number) => {
		const dragItem = blocks[dragIndex];

		if (dragItem) {
			const updatedBlocks = [...blocks];
			const prevItem = updatedBlocks?.splice(hoverIndex, 1, dragItem);
			updatedBlocks?.splice(dragIndex, 1, prevItem[0]);

			onLessonContentUpdate(updatedBlocks);
		}
	}

	const updateBlock = (id: number, value: any, file?: { file: string, id: number, name: string }) => {
		const updatedBlocks = [...blocks];

		updatedBlocks[updatedBlocks?.findIndex(item => item?.id === id)] = {
			...updatedBlocks[updatedBlocks?.findIndex(item => item?.id === id)],
			value
		};

		onLessonContentUpdate(updatedBlocks, file);
	}

	const copyBlock = (id: number) => {
		const updatedBlocks = [...blocks];

		const copiedItem = updatedBlocks?.find(item => item?.id === id);
		updatedBlocks.push({ ...copiedItem, id: maxBlockId + 1 });

		onLessonContentUpdate(updatedBlocks);
		onUpdateMaxBlockId();
	}

	const deleteBlock = (id: number) => {
		const updatedBlocks = [...blocks];

		onLessonContentUpdate(updatedBlocks?.filter(item => item?.id !== id));
	}

	return (
		<DndProvider backend={HTML5Backend} {...restProps}>
			<section ref={containerBottom} className={cn(styles.content, className)}>
				<LessonMenu<LessonBlockType>
					menuLabel='Добавить новый блок'
					onSelect={addLessonBlock}
					options={lessonBlockOptions}
				/>
				{
					blocks?.map((item: any, index: number) => (
						<Block
							moveBlocks={moveBlocks}
							onCopy={copyBlock}
							onDelete={deleteBlock}
							className={styles.block}
							id={item?.id}
							index={index}
							key={index}
						>
							{getLessonBlock(item?.type, item?.id, item?.value)}
						</Block>
					))
				}
			</section>
		</DndProvider>
	);
};

