import { useState } from 'react'
import { CancelToken } from 'axios'

type HttpRequest<T> = (body?: any, token?: any) => Promise<T>
type HttpCallback<T> = (data: T, body?: any, error?: HttpError) => void
type HttpConfig<T> = {
	headers: any,
	timeout: number
}
type HttpHook<T> = [(body?: any, token?: CancelToken) => Promise<void>, {
	loading: boolean,
	data: T | null,
	error: HttpError | null
}]

export class HttpError {
	status: number
	message: string

	constructor(status: number, message: string) {
		this.status = status
		this.message = message
	}
}

export function useRequest<T>(
	request: HttpRequest<T>,
	cb?: HttpCallback<T>,
	config?: HttpConfig<T>,
): HttpHook<T> {
	const [response, setResponse] = useState<T | null>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<HttpError | null>(null)

	async function fetchApi(body?: any, token?: CancelToken): Promise<void> {
		setLoading(true)
		let err = null
		let data: T = {} as T
		try {
			const response: any = await request(body, token)

			if(response.data) {
				data = response.data;
			} else {
				data = response;
			}
		} catch (_err: any) {
			err = _err
		} finally {
			setLoading(false)
			if (err) {
				if(cb) cb(data, err)
				setError(err)
			} else {
				setResponse(data)
				setError(null)
				if(cb) cb(data, body)
			}
		}
	}

	return [fetchApi, {loading, data: response, error}]
}
