import React, { useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'styles/ckeditor5.css';

const editorConfiguration = {
	toolbar: {
		shouldNotGroupWhenFull: true,
		items: [
			'undo',
			'redo',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'fontFamily',
			'fontSize',
			'fontBackgroundColor',
			'fontColor',
			'|',
			'link',
			'blockQuote',
			'|',
			'alignment',
			'bulletedList',
			'numberedList',
			'|'
		],
	},
	language: 'ru'
}

interface Props {
	value: string;
	onChange: (data: string) => void;
	disabled?: boolean
	state?: string | undefined;
}

export const TextEditor = (props: Props) => {
	const { value, onChange, disabled = false, state = undefined } = props;

	const [onFocus, setOnFocus] = useState(false);

	return (
		<div className={
			cn(
				styles.editor,
				state === 'error' ? styles.editor_error : '',
				onFocus ? styles.editor_focus : '',
				disabled ? styles.editor_disabled: ''
			)
		}>
			<CKEditor
				editor={Editor}
				config={editorConfiguration}
				data={value}
				onReady={() => {}}
				onChange={( event: any, editor: any ) => {
					const data = editor.getData();
					onChange(data);
				}}
				onFocus={() => setOnFocus(true)}
				onBlur={() => setOnFocus(false)}
				disabled={disabled}
			/>
		</div>
	);
}
