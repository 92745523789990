import React from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Block, LessonMenu, TextTaskBlock, TestTaskBlock, MultiTestTaskBlock } from 'pages/MethodistLesson/components';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';
import { LessonTaskType } from 'enums/lesson-task-type.enum';

const lessonTaskOptions = [
	{
		label: 'Текстовое задание',
		value: LessonTaskType.TEXT_TASK
	},
	{
		label: 'Тест',
		value: LessonTaskType.TEST_TASK
	},
	{
		label: 'Тест с мультивыбором',
		value: LessonTaskType.MULTI_TEST_TASK
	}
];

interface Props {
	lessonId: string | undefined;
	blocks: any[];
	onLessonTasksUpdate: (blocks: any[]) => void;
	maxBlockId: number;
	onUpdateMaxBlockId: () => void;
	className?: string;
}

export const LessonTasks = (props: Props) => {
	const {
		lessonId,
		blocks,
		onLessonTasksUpdate,
		maxBlockId,
		onUpdateMaxBlockId,
		className,
		...restProps
	} = props;

	const dispatch = useDispatch();

	const getLessonTaskBlock = (type: LessonTaskType, blockId: number, value: any, index: number) => {
		switch (type) {
			case LessonTaskType.TEXT_TASK:
				return <TextTaskBlock
					id={blockId}
					index={index}
					value={value}
					updateBlock={updateBlock}
				/>;

				break;

			case LessonTaskType.TEST_TASK:
				return <TestTaskBlock
					id={blockId}
					index={index}
					value={value}
					updateBlock={updateBlock}
				/>;

				break;

			case LessonTaskType.MULTI_TEST_TASK:
				return <MultiTestTaskBlock
					id={blockId}
					index={index}
					value={value}
					updateBlock={updateBlock}
				/>;

				break;
		}
	}

	const addLessonTask = (type: LessonTaskType) => {

		const updatedBlocks = blocks?.length > 0  ? [
			...blocks,
			{
				type,
				id: maxBlockId + 1
			}
		] : [
			{
				type,
				id: maxBlockId + 1
			}
		];

		onLessonTasksUpdate(updatedBlocks);
		onUpdateMaxBlockId();

		dispatch(setCurrentEditBlock(maxBlockId + 1));

		setTimeout(() => {
			window.scrollTo({ top: document.body.scrollHeight + 500, behavior: 'smooth' });
		}, 0);
	}

	const moveBlocks = (dragIndex: number, hoverIndex: number) => {
		const dragItem = blocks[dragIndex];

		if (dragItem) {
			const updatedBlocks = [...blocks];
			const prevItem = updatedBlocks?.splice(hoverIndex, 1, dragItem);
			updatedBlocks?.splice(dragIndex, 1, prevItem[0]);

			onLessonTasksUpdate(updatedBlocks);
		}
	}

	const updateBlock = (id: number, value: any) => {
		const updatedBlocks = [...blocks];

		updatedBlocks[updatedBlocks?.findIndex(item => item?.id === id)] = {
			...updatedBlocks[updatedBlocks?.findIndex(item => item?.id === id)],
			...value
		};

		onLessonTasksUpdate(updatedBlocks);
	}

	const copyBlock = (id: number) => {
		const updatedBlocks = [...blocks];

		const copiedItem = updatedBlocks?.find(item => item?.id === id);
		updatedBlocks.push({ ...copiedItem, id: maxBlockId + 1 });

		onLessonTasksUpdate(updatedBlocks);
		onUpdateMaxBlockId();
	}

	const deleteBlock = (id: number) => {
		const updatedBlocks = [...blocks];

		onLessonTasksUpdate(updatedBlocks?.filter(item => item?.id !== id));
	}

	return (
		<DndProvider backend={HTML5Backend} {...restProps}>
			<section className={cn(styles.content, className)}>
				<LessonMenu<LessonTaskType>
					menuLabel='Добавить новое задание'
					onSelect={addLessonTask}
					options={lessonTaskOptions}
					className={styles.tasks_menu}
				/>
				{
					blocks?.map((item: any, index: number) => (
						<Block
							moveBlocks={moveBlocks}
							onCopy={copyBlock}
							onDelete={deleteBlock}
							className={styles.block}
							id={item?.id}
							index={index}
							key={index}
						>
							{getLessonTaskBlock(item?.type, item?.id, item, index)}
						</Block>
					))
				}
			</section>
		</DndProvider>
	);
};

