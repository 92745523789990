import React from 'react'
import styles from './index.module.sass';
import cn from 'classnames';

interface Props {
	icon: string,
	width?: number,
	height?: number,
	size?: number,
	rotateDegrees?: number,
	className?: string
}

export const Icon = (props: Props) => {
	const {
		icon,
		width,
		height,
		size,
		rotateDegrees = 0,
		className = '',
		...restProps
	} = props;

	return (
		<svg
			width={width || size}
			height={height || size}
			transform={`rotate(${rotateDegrees})`}
			className={cn(styles.icon, className)}
			{...restProps}
		>
			<use href={`/sprite.svg#${icon}`} />
		</svg>
	);
};
