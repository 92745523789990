import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord } from 'dnd-core';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'components/base';
import useOnClickOutside from 'utils/hooks/clickOutside';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';

interface Props {
	id: number;
	index: number;
	moveBlocks: (dragIndex: number, hoverIndex: number) => void;
	onCopy: (id: number) => void;
	onDelete: (id: number) => void;
	className?: string;
}

export const Block: FC<Props> = (props) => {
	const {
		id,
		index,
		moveBlocks,
		onCopy,
		onDelete,
		children,
		className
	} = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const ref = useRef<HTMLDivElement>(null);
	const menu = useRef<HTMLDivElement>(null);

	const [menuIsMenuOpen, setMenuOpen] = useState(false);

	useOnClickOutside(menu, () => {
		setMenuOpen(false);
	});

	useEffect(() => {
		if (currentEditBlock === null) {
			drag(drop(ref));
		} else {
			drag(drop(null));
		}

	}, [currentEditBlock]);

	const [{ opacity }, drop] = useDrop({
		accept: 'card',
		hover(item: any, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveBlocks(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		collect: (monitor) => ({
			opacity: !!monitor.isOver() ? 0.4 : 1,
		})
	});

	const [, drag] = useDrag({
		type: 'card',
		item: { id, index },
		canDrag: monitor => currentEditBlock === null && !menuIsMenuOpen
	});

	const onEdit = () => {
		dispatch(setCurrentEditBlock(id));
		setMenuOpen(false);
	}

	const onCopyHandle = () => {
		onCopy(id);
		setMenuOpen(false);
	}

	const onDeleteHandle = () => {
		onDelete(id);
		setMenuOpen(false);
	}

	return (
		<div
			ref={ref}
			style={{ opacity }}
			className={cn(
				styles.block,
				className,
				currentEditBlock === null ? styles.block_draggable : ''
			)}
		>
			{children}
			{
				currentEditBlock !== id &&
				<div ref={menu} className={styles.block__control}>
					<button onClick={() => setMenuOpen(!menuIsMenuOpen)}>
						<Icon icon='gear' size={32} />
					</button>
					{
						menuIsMenuOpen &&
						<div className={styles.popup}>
							<button onClick={onEdit}>Редактировать</button>
							<button onClick={onCopyHandle}>Копировать</button>
							<button onClick={onDeleteHandle}>Удалить</button>
						</div>
					}
				</div>
			}
		</div>
	);
};
