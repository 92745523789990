import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './index.module.sass';
import { Footer, Header } from 'components/index';

export const MainLayout = () => {
	return (
		<div className={styles.wrapper} id='App'>
			<Header />
			<main className={styles.content}>
				<Outlet />
			</main>
			<Footer className={styles.footer} />
		</div>
	);
};
