import { saveAs } from 'file-saver';
import { safelyGet, safelyPut, safelyPost, safelyDelete } from 'services/request';
import { File as UserFile, Lesson } from 'models/lesson.interface';
import { Roles } from 'enums/roles.enum';

export const getMethodistLesson = async(id: number) => {
	const result = await safelyGet<Lesson>(`education/methodist/lesson/${id}/`);
	return result.response;
}

export const getLesson = async(id: number, role: Roles) => {
	const result = await safelyGet<Lesson>(`education/${role}/lesson/${id}/`);
	return result.response;
}

export const saveLessonContent = async (id: number, content: any) => {
	const result = await safelyPut<any>(`education/methodist/lesson/${id}/`, { content });
	return result.response;
}

export const saveLessonTasks = async (id: number, content: any) => {
	const result = await safelyPost<any>('education/methodist/homework/', { lesson: id, content });
	return result.response;
}

export const updateLessonTasks = async (lesson: number, homeworkId: number, content: any) => {
	const result = await safelyPut<any>(`education/methodist/homework/${homeworkId}/`, { lesson, content });
	return result.response;
}

export const uploadLessonFile = async(id: string, file: File | Blob, config: any) => {
	const formData = new FormData();
	formData.append('lesson', id);
	formData.append('file', file, (file as any).name);
	const result = await safelyPost<any>('education/methodist/lesson-file/', formData, config);
	return result.response;
}

export const uploadStudentAnswerFile = async(file: File | Blob, config: any) => {
	const formData = new FormData();
	formData.append('file', file, (file as any).name);
	const result = await safelyPost<UserFile>('education/student/homework-solution-file/', formData, config);
	return result.response;
}

export const deleteStudentAnswerFile = async(id: string) => {
	const result = await safelyDelete<any>(`education/student/homework-solution-file/${id}/`);
	return result.response;
}

export const getStudentAnswerFile = async(id: string) => {
	const result = await safelyGet<UserFile>(`education/student/homework-solution-file/${id}/`);
	return result.response;
}

export const removeLessonFile = async(id: number) => {
	const result = await safelyDelete<any>(`education/methodist/lesson-file/${id}`);
	return result.response;
}

export const downLoadDocument = async(url: string, fileName: string) => {
	const result = await safelyGet(url, { responseType: 'blob' });
	saveAs(result.response as Blob, fileName);
}
