import React from 'react';
import styles from './index.module.sass';
import { NavLink, useNavigate } from 'react-router-dom';
import { slide as BurgerMenu } from 'react-burger-menu';
import { useSelector } from 'react-redux';
import { Icon } from 'components/base';
import { StorageService } from 'services/storageService';
import { Roles } from 'enums/roles.enum';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	className?: string;
}

export const Menu = (props: Props) => {
	const { isOpen, onClose, className, ...restProps } = props;

	const navigate = useNavigate();
	const storage = StorageService.getInstance();

	const user = useSelector((state: any) => state.user.data);

	const onLogout = () => {
		storage.logout();
		navigate('/auth', { replace: true });
	}

	return (
		<BurgerMenu
			isOpen={isOpen}
			pageWrapId={'page-wrap'}
			outerContainerId={'App'}
			className={styles.menu}
			burgerButtonClassName={styles.menu__button}
			overlayClassName={styles.menu__overlay}
			itemListClassName={styles.menu__list}
			customCrossIcon={ <Icon icon='close' size={24} /> }
			bodyClassName={styles.body}
			onClose={onClose}
			{...restProps}
		>
			<ul>
				<li>
					<NavLink
						to='/profile'
						style={({ isActive }) => ({
							color: isActive ? '#d86d8f' : '',
						})}
						onClick={onClose}
					>
						Профиль
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/courses'
						style={({ isActive }) => ({
							color: isActive ? '#d86d8f' : '',
						})}
						onClick={onClose}
					>
						Мои курсы
					</NavLink>
				</li>
				{
					user?.role === Roles.CURATOR &&
					<li>
						<NavLink
							to='/tasks'
							style={({ isActive }) => ({
								color: isActive ? '#d86d8f' : '',
							})}
						>
							Решения
						</NavLink>
					</li>
				}
				<li>
					<NavLink
						to='/faq'
						style={({ isActive }) => ({
							color: isActive ? '#d86d8f' : '',
						})}
						onClick={onClose}
					>
						Вопросы и ответы
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/contacts'
						style={({ isActive }) => ({
							color: isActive ? '#d86d8f' : '',
						})}
						onClick={onClose}
					>
						Контакты
					</NavLink>
				</li>
				<li>
					<button onClick={onLogout}>Выйти</button>
				</li>
			</ul>
		</BurgerMenu>
	);
};
