import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Size, TextEditor, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';

interface Props {
	id: number;
	value: string;
	updateBlock?: (id: number, value: string) => void;
	className?: string;
}

export const TextBlock = (props: Props) => {
	const { id, value, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const [editorValue, setEditorValue] = useState(value);

	useEffect(() => {
		setEditorValue(value);
	}, [currentEditBlock]);

	const save = () => {
		updateBlock(id, editorValue);
		dispatch(setCurrentEditBlock(null));
	}

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
		setEditorValue(value);
	}

	const setEditorValueHandle = (value: string) => {
		setEditorValue(value);
	}

	return (
		<>
			{
				currentEditBlock === id ? (
					<div className={className}>
						<TextEditor value={editorValue} onChange={setEditorValueHandle} />
						<div className={styles.controls}>
							<Button
								theme={Theme.SECONDARY}
								size={Size.S}
								onClick={cancel}
								className={styles.button}
							>
								Отменить
							</Button>
							<Button
								theme={Theme.PRIMARY}
								size={Size.S}
								onClick={save}
								className={styles.button}
							>
								Сохранить
							</Button>
						</div>
					</div>
				) : (
					<p dangerouslySetInnerHTML={{ __html: value }}></p>
				)
			}
		</>
	);
};
