import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldArray, Form, Formik } from 'formik';
import cn from 'classnames';
import { toast, ToastContainer } from 'react-toastify';
import { useRequest } from 'utils/hooks/useRequest';
import { checkHomeWork, getSolution } from 'actions/homework';
import { HomeWorkSolution } from 'models/lesson.interface';
import { Button, Checkbox, Icon, Radio, Size, Status, Theme } from 'components/base';
import { LessonTaskType } from 'enums/lesson-task-type.enum';
import { HomeworkStatus } from 'enums/homework-status.enum';
import { CuratorTextTask } from './components/CuratorTextTask';

export const CuratorTask = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [solution, setSolution] = useState<HomeWorkSolution>();
	const [loadingHomeWorkAccepting, setLoadingHomeWorkAccepting] = useState(false);
	const [loadingHomeWorkReturning, setLoadingHomeWorkReturning] = useState(false);

	const [getSolutionHandle, { loading }] = useRequest<HomeWorkSolution>(
		(id) => getSolution(id),
		(data: HomeWorkSolution) => {
			setSolution(data);
		}
	);

	useEffect(() => {
		id && getSolutionHandle(+id);
	}, []);

	const goBack = () => {
		navigate(-1);
	}

const getHomeWorkTask = (task: any, values: any, value: { id: number, comment: string, score: string }, fieldName: string, setFieldValue: Function) => {
	const answer = solution?.content?.find(item => item?.id === task?.id)?.answer;

	const getTestAnswerColor = (optionId: number) => {
		if (Array.isArray(answer)) {
			const optionAnswer = answer?.find((item: any) => item?.id === optionId);
			if (optionAnswer?.is_correct) {
				return '#5abd0d';
			}

			if (optionAnswer?.selected && !optionAnswer?.is_correct) {
				return '#d86d8f';
			}

			return;
		}

		if (answer?.id === optionId) {
			return answer?.is_correct ? '#5abd0d' : '#d86d8f';
		} else {
			return answer?.is_correct ? '': '#5abd0d';
		}

		return '';
	}

	switch (task?.type) {
		case LessonTaskType.TEXT_TASK:
			return (
				<CuratorTextTask
					question={task?.text}
					answer={answer}
					comment={value.comment}
					onCommentChange={(value) => setFieldValue(`${fieldName}.comment`, value)}
					score={value.score}
					onScoreChange={(value) => setFieldValue(`${fieldName}.score`, value)}
					disabled={solution?.status !== HomeworkStatus.RESOLVED}
				/>
			);

			break;

		case LessonTaskType.TEST_TASK:
			return (
				<>
					<p
						dangerouslySetInnerHTML={{ __html: task?.text }}
						className={styles.block__text}
					></p>
					{
						task?.options?.map((option: { id: number, value: string }, index: number) => (
							<Radio
								key={index}
								value={option?.id}
								checked={answer?.id === option?.id}
								className={styles.block__option}
								disabled={true}
							>
								<p
									style={{ color: getTestAnswerColor(option?.id) }}
									dangerouslySetInnerHTML={{ __html: option?.value }}
								></p>
							</Radio>
						))
					}
				</>
			);

			break;

		case LessonTaskType.MULTI_TEST_TASK:
			return (
				<>
					<p
						dangerouslySetInnerHTML={{ __html: task?.text }}
						className={styles.block__text}
					></p>
					{
						Array.isArray(answer) &&
						task?.options?.map((option: { id: number, value: string }) => (
							<Checkbox
								key={option?.id}
								value={answer?.find(item => item?.id === option?.id)?.selected}
								className={styles.block__option}
								disabled={true}
							>
								<p
									style={{ color: getTestAnswerColor(option?.id) }}
									dangerouslySetInnerHTML={{ __html: option?.value }}
								></p>
							</Checkbox>
						))
					}
				</>
			);

			break;
		}
	}

	const getSolutionScore = (taskId: number) => {
		return solution?.content?.find(item => item?.id === taskId)?.score;
	}

	const getFormatedAnswer = () => {
		return solution?.content
			?.filter(block => block?.type === LessonTaskType.TEXT_TASK)
			?.map(item => { return { id: item?.id, comment: item?.comment, score: item?.score || 0 } });
	}

	const isFormAcceptingDisabled = (answers: Array<{ id: number, comment: string, score: string }>) => {
		return answers?.length !== solution?.homework?.content?.blocks
				?.filter(block => block?.type === LessonTaskType.TEXT_TASK)?.length ||
			!!answers?.some(answer => answer?.score === '');
	}

	const returnHomeWork = (answers: any[] | undefined) => {
		setLoadingHomeWorkReturning(true);

		checkHomeWork(
			+id!,
			HomeworkStatus.RETURNED,
			answers?.map(item => { return { id: item?.id, comment: item?.comment } })
		).then(() => {
			setSolution({ ...solution, status: HomeworkStatus.RETURNED } as HomeWorkSolution);
			toast.success('Задание отправлено на доработку', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		}, () => {
			toast.error('Не удалось отправить задание на доработку', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		}).finally(() => {
			setLoadingHomeWorkReturning(false);
		});
	}

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<section className={styles.container}>
				<ToastContainer />
				<Button
					theme={Theme.SECONDARY}
					size={Size.S}
					onClick={goBack}
					className={cn(styles.button, styles.button_back)}
				>
					<Icon icon='arrow' size={12} className={styles.button_back__icon} />
					<span>Назад</span>
				</Button>
				{
					!!solution?.status &&
					<Status
						status={solution?.status}
						score={solution?.score}
						className={styles.status}
					/>
				}
				<Formik
					initialValues={{
						answers: getFormatedAnswer()
					}}
					enableReinitialize={true}
					onSubmit={(
						values
					) => {
						setLoadingHomeWorkAccepting(true);
						checkHomeWork(
							+id!,
							HomeworkStatus.VERIFIED,
							values.answers?.map(item => { return { id: item?.id, score: +item?.score } })
						).then(() => {
							setSolution({
								...solution, status: HomeworkStatus.VERIFIED,
								score: solution?.score! + values.answers?.reduce((sum, { score }) => sum + Number(score), 0)!
							} as HomeWorkSolution);
							toast.success('Задание оценено', {
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								theme: 'dark',
								bodyStyle: { fontFamily: 'Circe' }
							});
						}, () => {
							toast.error('Не удалось оценить задание', {
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								theme: 'dark',
								bodyStyle: { fontFamily: 'Circe' }
							});
						}).finally(() => {
							setLoadingHomeWorkAccepting(false);
						});
					}}
				>
					{({ values, setFieldValue }) => (
						<Form className={styles.form} noValidate>
							<FieldArray
								name='answers'
								render={() => (
									<>
										{
											solution?.homework?.content?.blocks?.map((task, index) => (
												<div className={styles.block} key={index}>
													<div className={styles.block__caption}>
														<h3>Задание № {index + 1}</h3>
														<span className={styles.block__caption__score}>
														{
															getSolutionScore(task?.id) !== undefined &&
															<>{getSolutionScore(task?.id)} из </>
														}
															{task?.max_score}
														</span>
													</div>
													{
														getHomeWorkTask(
															task,
															values,
															values?.answers && values?.answers[index] ?
																values.answers[index] : { id: 0, comment: '', score: '0' },
															`answers.${index}`,
															setFieldValue
														)
													}
												</div>
											))
										}
									</>
								)}
							/>
							{
								solution?.status === HomeworkStatus.RESOLVED &&
								<section className={styles.controls}>
									<Button
										theme={Theme.SECONDARY}
										size={Size.M}
										type='button'
										isLoading={loadingHomeWorkReturning}
										className={styles.controls__button}
										onClick={() => returnHomeWork(values.answers)}
									>
										Отправить на доработку
									</Button>
									<Button
										size={Size.M}
										type='submit'
										isLoading={loadingHomeWorkAccepting}
										className={styles.controls__button}
										disabled={values.answers && isFormAcceptingDisabled(values.answers)}
									>
										Принять
									</Button>
								</section>
							}
						</Form>
					)}
				</Formik>
			</section>
		);
	}, [solution, loadingHomeWorkReturning, loadingHomeWorkAccepting]);

	return <>{content}</>;
};
