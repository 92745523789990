import React, { useMemo, useState } from 'react';
import styles from './index.module.sass';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Size, TextInput, Theme } from 'components/base';
import 'styles/react-modal.css';
import { toast } from 'react-toastify';
import { updateUserPassword } from 'actions/user';

const PasswordChangeFormSchema = Yup.object().shape({
	old_password: Yup.string()
		.required('Заполните поле'),
	new_password1: Yup.string()
		.required('Заполните поле'),
	new_password2: Yup.string()
		.required('Заполните поле')
		.oneOf([Yup.ref('new_password1'), null], 'Пароли должны совпадать')
});

interface Props {
	onClose: () => void
}

export const PasswordChangeForm = (props: Props) => {
	const { onClose } = props;

	const [isLoading, setIsLoading] = useState(false);

	const notify = (success: boolean) => {
		if (success) {
			toast.success('Пароль обновлен', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		} else {
			toast.error('Не удалось обновить пароль', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		}
	}

	const content = useMemo(() => {
		return (
			<Formik
				initialValues={{
					old_password: '',
					new_password1: '',
					new_password2: ''
				}}
				validationSchema={PasswordChangeFormSchema}
				onSubmit={(
					values
				) => {
					setIsLoading(true);
					updateUserPassword(values).then(() => {
						setIsLoading(false);
						onClose();
						notify(true);
					}, () => {
						setIsLoading(false);
						onClose();
						notify(false);
					});
				}}
			>
				{({ values, setFieldValue, isValid, resetForm }) => (
					<Form className={styles.form} noValidate>
						<TextInput
							label='Старый пароль'
							type='password'
							value={values.old_password}
							onChange={event => setFieldValue('old_password', event.target.value)}
							className={styles.form__field}
						/>
						<TextInput
							label='Новый пароль'
							type='password'
							value={values.new_password1}
							onChange={event => setFieldValue('new_password1', event.target.value)}
							className={styles.form__field}
						/>
						<TextInput
							label='Повторите новый пароль'
							type='password'
							value={values.new_password2}
							onChange={event => setFieldValue('new_password2', event.target.value)}
							className={styles.form__field}
						/>
						<Button
							stretch
							disabled={!isValid}
							type='submit'
							className={styles.button}
							isLoading={isLoading}
						>
							Сохранить
						</Button>
						<Button
							stretch
							theme={Theme.SECONDARY}
							onClick={onClose}
							size={Size.M}
						>
							Отмена
						</Button>
					</Form>
				)}
			</Formik>
		);
	}, [isLoading]);

	return <>{content}</>;
};
