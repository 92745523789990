import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.sass';
import { Course } from 'models/course.interface';
import { formatDate } from 'utils/helpers/date-formater';
import { Icon } from 'components/base';

interface Props {
	course: Course;
	courseCompleted?: boolean;
	className?: string;
}

export const CourseCard = (props: Props) => {
	const { course, courseCompleted = false, className, ...restProps } = props;

	return (
		<div {...restProps} className={cn(styles.card, course?.course_available ? styles.card_active : '', className)}>
			<div>
				<div
					className={styles.tariff}
					style={{ background: course?.color && !courseCompleted ? course?.color : '#cdd6d8' }}
				>
					{course?.title}
				</div>
				{
					course?.course_available ? (
						<NavLink to={`	${course?.id}`} className={styles.link}>
							<h3>{course?.course?.title}</h3>
						</NavLink>
					) : (
						<h3>{course?.course?.title}</h3>
					)
				}
				<p className={styles.description}>
					{course?.course?.description}
				</p>
				<div className={styles.dates}>
					<div>
						<span className={styles.dates__caption}>Начало курса</span>
						<h4 className={styles.dates__value}>{formatDate(course?.course?.start_date, 'dd.LL')}</h4>
					</div>
					<div>
						<span className={styles.dates__caption}>Окончание курса</span>
						<h4 className={styles.dates__value}>{formatDate(course?.course?.end_date, 'dd.LL')}</h4>
					</div>
				</div>
			</div>
			<div>
				{
					!course?.course_available && !courseCompleted &&
					<div className={styles.message}>
						<p>
							Курс будет доступен в ближайшее время. Мы тоже с нетерпением ждем этого момента.
							С уважением,  команда Селлер
						</p>
					</div>
				}
				{
					courseCompleted &&
					<div className={cn(styles.message, styles.message_finished)}>
						<p>
							Курс завершен
						</p>
					</div>
				}
				{
					course?.course_available &&
					<>
						<div className={styles.progressbar_container}>
							<span className={styles.label}>Прогресс курса</span>
							<div className={styles.progressbar}>
								<span style={{ width: `${course?.progress}%`, background: course?.color }}></span>
							</div>
						</div>
						<div className={styles.routing} style={{ color: course?.color }}>
							<h4>Перейти к курсу</h4>
							<Icon icon='line' width={100} height={10} />
						</div>
					</>
				}
			</div>
		</div>
	);
};
