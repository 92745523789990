import { createSlice } from '@reduxjs/toolkit';

const currentEditBlockSlice = createSlice({
	name: 'currentEditBlock',
	initialState: {
		id: null
	},
	reducers: {
		setCurrentEditBlock(state, action) {
			state.id = action.payload
		}
	}
});

export default currentEditBlockSlice.reducer;
export const { setCurrentEditBlock } = currentEditBlockSlice.actions;

