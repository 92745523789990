import React from 'react';
import styles from './index.module.sass';

export const Contacts = () => {
	return (
		<div className={styles.container}>
			<h2>Контакты</h2>
			<section className={styles.content}>
				<div>
					<span>Вопросы по курсу</span>
					<a href = 'mailto: help@sellerhelp.ru'>
						<h3>help@sellerhelp.ru</h3>
					</a>
				</div>
				<div>
					<span>Контактный номер</span>
					<a href = 'tel: +79063307208'>
						<h3>8 906 330 72 08</h3>
					</a>
				</div>
				<div className={styles.link}>
					<span>Социальная сеть</span>
					<a href='https://t.me/sellerhelp_news' target='_blank' rel='noopener noreferrer'>
						<h3>Телеграм</h3>
					</a>
				</div>
				<div className={styles.link}>
					<span>Социальная сеть</span>
					<a href='https://vk.com/sellerhelp' target='_blank' rel='noopener noreferrer'>
						<h3>Вконтакте</h3>
					</a>
				</div>
			</section>
		</div>
	);
};
