import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'components/base';
import { getModuleLessons } from 'actions/courses';
import { useRequest } from 'utils/hooks/useRequest';
import { LessonsList } from 'models/lesson.interface';
import { setCourse } from 'reducers/courseSlice';

export const ModuleLessons = () => {
	const { id } = useParams();
	const user = useSelector((state: any) => state.user.data);
	const dispatch = useDispatch();

	const [data, setModule] = useState<LessonsList>();

	const [getModuleLessonsHandle, { loading }] = useRequest<LessonsList>(
		(id, role) => getModuleLessons(+id, role),
		(data: LessonsList) => {
				setModule(data);
				dispatch(setCourse({
					...data?.course,
					lessons_count: data?.lessons_count,
					lessons_duration: data?.lessons_duration
				}));
			}
	);

	useEffect(() => {
		id && getModuleLessonsHandle(+id, user?.role);
	}, []);

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<section className={styles.lessons}>
				{
					data?.lessons?.map((lesson, index) => (
						<div
							key={index}
							className={styles.lessons__item}
						>
							<h3>{lesson?.title}</h3>
							<NavLink
								to={`lessons/${lesson?.id}`}
							>
								<button className={styles.button}>
									<Icon icon='chevron' size={20} />
								</button>
							</NavLink>
						</div>
					))
				}
			</section>
		);
	}, [data, loading])

	return <>{content}</>;
};
