import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import cn from 'classnames';
import styles from './index.module.sass';
import { Button, Size, TextInput } from 'components/base';
import { resetPassword } from 'actions/user';

import logo from 'assets/images/logo.png';

interface Props {
	className?: string
}

const ResetPasswordFormSchema = Yup.object().shape({
	email: Yup.string()
		.email()
		.required('Заполните поле')
});

export const ResetPasswordPage = (props: Props) => {
	const {
		className,
		...restProps
	} = props;

	const [changePasswordError, setChangePasswordError] = useState('');
	const [showSuccessSendLetterMessage, setShowSuccessSendLetterMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div {...restProps} className={cn(styles.wrapper, className)}>
			<img src={logo} alt='logo' className={styles.logo} />
			<h1>Восстановление пароля</h1>
			{
				showSuccessSendLetterMessage ? (
					<p className={styles.success_message}>Мы отправили вам письмо с ссылкой для востановления пароля</p>
				) : (
					<Formik
						initialValues={{
							email: ''
						}}
						validationSchema={ResetPasswordFormSchema}
						onSubmit={(
							values
						) => {
							setIsLoading(true);
							resetPassword(values.email).then(() => {
								setShowSuccessSendLetterMessage(true);
								setIsLoading(false);
							}, () => {
								setChangePasswordError('Пользователь не найден');
								setIsLoading(false);
							});
						}}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form className={styles.form} noValidate>
								<TextInput
									label='E-mail'
									value={values.email}
									onChange={event => setFieldValue('email', event.target.value)}
									className={styles.form__field}
								/>
								<p className={styles.form__error}>
									{changePasswordError}
								</p>
								<Button
									stretch
									size={Size.L}
									disabled={!isValid}
									type='submit'
									isLoading={isLoading}
								>
									Отправить
								</Button>
							</Form>
						)}
					</Formik>
				)
			}
			<div className={styles.link}>
				<Link to='/auth'>Назад</Link>
			</div>
		</div>
	);
};

