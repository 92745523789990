import { safelyGet, safelyPost, safelyPut } from 'services/request';
import { HomeworkStatus } from 'enums/homework-status.enum';
import { HomeWorkSolution } from 'models/lesson.interface';
import { AnswerList } from 'models/answer.interface';

export const sendTaskSolution = async (homework: number, content: any) => {
	const result = await safelyPost<any>('education/student/homework-solution/', { homework, content, status: HomeworkStatus.RESOLVED });
	return result.response;
}

export const updateTaskSolution = async (solution_id: number, content: any) => {
	const result = await safelyPut<any>(`education/student/homework-solution/${solution_id}/`, { content, status: HomeworkStatus.RESOLVED });
	return result.response;
}

export const getSolutions = async (status: HomeworkStatus, pagination: { offset: number, limit: number }) => {
	const result = await safelyGet<AnswerList>(
		'education/curator/homework-solution/',
		{
			params: {
				status,
				limit: pagination.limit,
				offset: pagination.offset
			}
		});
	return result.response;
}

export const getSolution = async (id: number) => {
	const result = await safelyGet<HomeWorkSolution>(`education/curator/homework-solution/${id}/`);
	return result.response;
}

export const checkHomeWork = async (id: number, status: HomeworkStatus, mark_content?: Array<{ id: number, comment?: string, score?: number }>) => {
	const result = await safelyPut<any>(`education/curator/homework-solution/${id}/`, { status, mark_content });
	return result.response;
}
