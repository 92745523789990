import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { toast, ToastContainer } from 'react-toastify';
import { useRequest } from 'utils/hooks/useRequest';
import { getMethodistLesson, saveLessonContent, saveLessonTasks, updateLessonTasks } from 'actions/lesson';
import { HomeWork, Lesson } from 'models/lesson.interface';
import { Button, Icon, Size, Theme } from 'components/base';
import { LessonContent, LessonTasks } from './components';

export const MethodistLesson = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [lesson, setLesson] = useState<Lesson>();
	const [lessonTasks, setLessonTasks] = useState<HomeWork>();
	const [currentMaxBlockId, setCurrentMaxBlockId] = useState(0);
	const [isSaving, setSaving] = useState(false);

	const [getLessonHandle, { loading }] = useRequest<Lesson>(
		(id: number) => getMethodistLesson(id),
		(data: Lesson) => {
			setLesson({ ...data, files: !!data?.files ? data?.files : [], content: !!data?.content ? data?.content : { blocks: [] } });
			setLessonTasks(!!data?.homework ? data?.homework : { id: -1, content: { blocks: [] } });

			const contentMaxId = !!data?.content && data?.content?.blocks?.length > 0 ?
				Math.max(...data?.content?.blocks?.map(item => item?.id)) + 1 : 0;
			const tasksMaxId = !!data?.homework && data?.homework?.content?.blocks?.length > 0 ?
				Math.max(...data?.homework?.content?.blocks?.map(item => item?.id)) + 1 : 0;
			setCurrentMaxBlockId(Math.max(contentMaxId, tasksMaxId));
		}
	);

	useEffect(() => {
		id && getLessonHandle(+id);
	}, []);

	const goBack = () => {
		navigate(-1);
	}

	const onSave = () => {
		setSaving(true);
		id && saveLessonContent(+id, lesson?.content).then(() => {
			if (lessonTasks?.id === -1) {
				saveLessonTasks(+id, lessonTasks?.content).then((lessonTasks) => {
					setLessonTasks(lessonTasks);

					toast.success('Занятие обновлено', {
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						theme: 'dark',
						bodyStyle: { fontFamily: 'Circe' }
					});

				});
			} else {
				updateLessonTasks(+id, +lessonTasks?.id!, lessonTasks?.content).then(() => {
					toast.success('Занятие обновлено', {
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						theme: 'dark',
						bodyStyle: { fontFamily: 'Circe' }
					});

				});
			}

		})
			.finally(() => setSaving(false));
	}

	const updateLessonContent = (blocks: any[], file?: { file: string, id: number, name: string }) => {
		setLesson({
			...lesson,
			content: { blocks },
			files: [...lesson?.files!, file]
		} as Lesson);
	}

	const onLessonTasksUpdate = (blocks: any[]) => {
		setLessonTasks({ ...lessonTasks, ...{ content: { blocks } }} as HomeWork);
	}

	const updateMaxBlockId = () => {
		setCurrentMaxBlockId(currentMaxBlockId + 1);
	}

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<div className={styles.container}>
				<ToastContainer />
				<Button
					theme={Theme.SECONDARY}
					size={Size.S}
					onClick={goBack}
					className={cn(styles.button, styles.button_back)}
				>
					<Icon icon='arrow' size={12} className={styles.button_back__icon} />
					<span>Назад</span>
				</Button>
				<h2>{lesson?.title}</h2>
				<Button
					theme={Theme.PRIMARY}
					size={Size.S}
					onClick={onSave}
					isLoading={isSaving}
					className={cn(styles.button, styles.button_save)}
				>
					Сохранить
				</Button>
				<LessonContent
					lessonId={id}
					blocks={lesson?.content?.blocks!}
					files={lesson?.files!}
					onLessonContentUpdate={updateLessonContent}
					maxBlockId={currentMaxBlockId}
					onUpdateMaxBlockId={updateMaxBlockId}
				/>
				<LessonTasks
					lessonId={id}
					blocks={lessonTasks?.content?.blocks!}
					onLessonTasksUpdate={onLessonTasksUpdate}
					maxBlockId={currentMaxBlockId}
					onUpdateMaxBlockId={updateMaxBlockId}
				/>
			</div>
		)
	}, [loading, isSaving, lesson, lessonTasks, updateLessonContent])

	return <>{content}</>;
};

