import React, { ChangeEvent, KeyboardEventHandler, ReactElement } from 'react';
import cn from 'classnames';
import styles from './index.module.sass';
import { Icon } from 'components/base/Icon';

interface Props {
	value: string | number,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void,
	type?: string,
	placeholder?: string,
	label?: string,
	hasClear?: boolean,
	suffixIcon?: ReactElement,
	className?: string,
	disabled?: boolean,
}

export const TextInput = (props: Props) => {
	const {
		value,
		onChange,
		type = 'text',
		placeholder = '',
		label = '',
		hasClear = false,
		suffixIcon,
		className,
		disabled = false,
		...restProps
	} = props;

	return (
		<div className={cn(styles.container, className)}>
			{
				label && <span className={styles.input__label}>{label}</span>
			}
			<div className={cn(styles.input, disabled ? styles.input_disabled : '')}>
				<input
					{...restProps}
					value={value}
					type={type}
					onChange={onChange}
					placeholder={placeholder}
					disabled={disabled}
				/>
				{
					hasClear &&
					<button className={styles.input__clear}>
						<Icon
							icon='close'
							size={12}
						/>
					</button>
				}
				<div className={styles.input__suffix}>{suffixIcon}</div>
			</div>
		</div>
	);
}
