import React from 'react';
import styles from './index.module.sass';

interface Props {
	percent: number;
}

export const ProgressBar = (props: Props) => {
	const { percent, ...restProps } = props;

	return (
		<div className={styles.container}>
			<svg
				height='30'
				width='30'
				style={{ strokeDashoffset: 75 - 75 * (percent / 100) }}
				className={styles.progressbar}
				{...restProps}
			>
				<circle cx='15' cy='15' r='12' stroke='#df5b72' strokeWidth='3' fill='white' />
			</svg>
			<span className={styles.percent}>{percent}%</span>
		</div>
	);
}
