import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import { Accordion } from 'components/base';
import { getFaqQuestions } from 'actions/faq';
import { useRequest } from 'utils/hooks/useRequest';
import { FaqQuestion, FaqQuestionsList } from 'models/faq.interface';

export const Faq = () => {
	const [questions, setQuestions] = useState<FaqQuestion[]>([]);

	const [getFaqQuestionsHandle] = useRequest<FaqQuestionsList>(
		() => getFaqQuestions(),
		(data: FaqQuestionsList) => setQuestions(data?.results)
	);

	useEffect(() => {
		getFaqQuestionsHandle();
	}, []);

	return (
		<div className={styles.container}>
			<h2>Вопросы и ответы</h2>
			<div className={styles.content}>
				{
					questions?.map((item, key) => (
						<Accordion key={key} title={item?.question} text={item?.answer} />
					))
				}
			</div>
		</div>
	);
};

