import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { NavLink } from 'react-router-dom';
import { useRequest } from 'utils/hooks/useRequest';
import { getSolutions } from 'actions/homework';
import { Answer, AnswerList } from 'models/answer.interface';
import { formatDate } from 'utils/helpers/date-formater';
import { Button, Status } from 'components/base';
import { Select } from 'components/base/Select';
import { HomeworkStatus } from 'enums/homework-status.enum';

export const CuratorTasks = () => {
	const statusOptions = [
		{
			value: HomeworkStatus.RESOLVED,
			label: 'Требует проверки'
		},
		{
			value: HomeworkStatus.RETURNED,
			label: 'Требует доработки'
		},
		{
			value: HomeworkStatus.VERIFIED,
			label: 'Принято'
		},
	];

	const [pagination, setPagination] = useState({ limit: 15, offset: 0 });
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [solutionList, setSolutionList] = useState<Answer[]>([]);
	const [statusFilter, setStatusFilter] = useState<HomeworkStatus>(HomeworkStatus.RESOLVED);

	const [getSolutionsHandle, { loading }] = useRequest<AnswerList>(
		() => getSolutions(statusFilter, pagination),
		(data: AnswerList) => {
			setSolutionList(pagination.offset > 0 ? [...solutionList,...data?.results] : data?.results);
			setPagination({ ...pagination, offset: pagination.offset + pagination?.limit });
			setShowLoadMore(data?.count > [...solutionList,...data?.results].length);
		}
	);

	useEffect(() => {
		getSolutionsHandle();
	}, [statusFilter]);

	const onStatusFilterChange = (status: HomeworkStatus) => {
		setPagination({ ...pagination, offset: 0 });
		setStatusFilter(status);
	}

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<section className={styles.container}>
				<h1>Решения</h1>
				<section className={styles.controls}>
					<Select<HomeworkStatus>
						value={statusFilter}
						options={statusOptions}
						onChange={onStatusFilterChange}
					/>
				</section>
				<section className={styles.answers}>
					{
						solutionList?.map((solution, index) => (
							<div
								key={index}
								className={styles.answers__item}
							>
								<span className={styles.score}>{formatDate(solution?.resolved_time, 'dd.LL.yyyy HH:mm')}</span>
								<div className={styles.info}>
									<NavLink to={`${solution?.id}`} className={styles.link}>
										<h4>{solution?.student?.last_name} {solution?.student?.first_name}</h4>
									</NavLink>
									<h4>{solution?.lesson_title} / {solution?.module_title}</h4>
									<h4>{solution?.course_title} - {solution?.tariff_title}</h4>
								</div>
								<Status
									status={solution?.status}
									score={solution?.score}
									className={styles.status}
								/>
							</div>
						))
					}
					{
						solutionList?.length === 0 && <h3 className={styles.answers_empty}>Решений пока нет</h3>
					}
				</section>
				{
					showLoadMore &&
					<Button
						onClick={getSolutionsHandle}
						className={styles.load_button}
					>Загрузить еще</Button>
				}
			</section>
		);
	}, [solutionList, statusFilter, pagination, showLoadMore]);

	return <>{content}</>;
};
