import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { BrowserView } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useRequest } from 'utils/hooks/useRequest';
import { getLesson } from 'actions/lesson';
import { HomeWorkSolution, Lesson as LessonInterface } from 'models/lesson.interface';
import { Button, Icon, Size, Status, Theme } from 'components/base';
import { LessonBlockType } from 'enums/lesson-block-type.enum';
import { DocumentBlock, ImageBlock, TextBlock, VideoBlock } from 'pages/MethodistLesson/components';
import { LessonComments } from 'components';
import { StudentHomework } from './components/StudentHomework';
import { Roles } from 'enums/roles.enum';
import { HomeworkStatus } from 'enums/homework-status.enum';

export const Lesson = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const user = useSelector((state: any) => state.user.data);

	const [lesson, setLesson] = useState<LessonInterface>();

	const [getLessonHandle, { loading }] = useRequest<LessonInterface>(
		(id: number) => getLesson(id, user?.role),
		(data: LessonInterface) => setLesson({ ...data, content: !!data?.content ? data?.content : { blocks: [] } })
	);

	useEffect(() => {
		id && getLessonHandle(+id);
	}, []);

	const goBack = () => {
		navigate(-1);
	}

	const getFile = (fileId: number) => {
		return lesson?.files?.find(item => item?.id === fileId);
	}

	const updateHomeWorkSolution = (solution: HomeWorkSolution) => {
		setLesson({ ...lesson, homework_solution: solution } as LessonInterface);
	}

	const getLessonBlock = (type: LessonBlockType, blockId: number, value: any) => {
		switch (type) {
			case LessonBlockType.TEXT:
				return <TextBlock
					id={blockId}
					value={value}
				/>;

				break;

			case LessonBlockType.IMAGE:
				return <ImageBlock
					id={blockId}
					lessonId={id!}
					image={getFile(value?.id)!}
				/>;

				break;

			case LessonBlockType.VIDEO:
				return id && <VideoBlock
					id={blockId}
					lessonId={id}
					video={getFile(value?.id)!}
				/>;

				break;

			case LessonBlockType.DOCUMENT:
				return id && <DocumentBlock
					id={blockId}
					lessonId={id}
					file={{ ...value, file: getFile(value?.id)?.file! }}
				/>;

				break;
		}
	}

	const content = useMemo(() => {
		if (loading) {
			return <></>;
		}

		return (
			<div className={styles.container}>
				<ToastContainer />
				<BrowserView>
					<Button
						theme={Theme.SECONDARY}
						size={Size.S}
						onClick={goBack}
						className={cn(styles.button, styles.button_back)}
					>
						<Icon icon='arrow' size={12} className={styles.button_back__icon} />
						<span>Назад</span>
					</Button>
				</BrowserView>
				{
					!!lesson?.homework_solution?.status &&
					<Status
						status={lesson?.homework_solution?.status}
						score={lesson?.homework_solution?.score}
						className={styles.status}
					/>
				}
				<h2>{lesson?.title}</h2>
				<section className={styles.content}>
					{
						lesson?.content?.blocks?.map((item: any, index: number) => (
							<div className={styles.block} key={index}>
								{getLessonBlock(item?.type, item?.id, item?.value)}
							</div>
						))
					}
					{
						lesson?.homework?.content &&
						lesson?.homework?.content?.blocks?.length > 0 &&
						<StudentHomework
							homework_id={lesson?.homework?.id}
							tasks={lesson?.homework?.content?.blocks}
							updateHomeWorkSolution={updateHomeWorkSolution}
							solution={lesson?.homework_solution}
							disabledTextTasks={
								user?.role === Roles.CURATOR ||
								lesson?.homework_solution?.status === HomeworkStatus.RESOLVED ||
								lesson?.homework_solution?.status === HomeworkStatus.VERIFIED
							}
							disabled={user?.role === Roles.CURATOR || !!lesson?.homework_solution}
							className={styles.homework}
						/>
					}
				</section>
				<LessonComments
					lesson_id={lesson?.id!}
					role={user?.role}
				/>
			</div>
		)
	}, [loading, lesson])

	return <>{content}</>;
};

