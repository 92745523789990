import React from 'react';
import styles from './index.module.sass';
import cn from 'classnames';
import { slide as BurgerMenu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from 'components/base';
import { Notification } from 'models/notification.interface';
import { NotificationType } from 'enums/notification-type.enum';
import { formatDate } from 'utils/helpers/date-formater';
import { Roles } from 'enums/roles.enum';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onNotificationRead: (id: number) => void;
	notifications: Notification[] | undefined;
	className?: string;
}

export const Notifications = (props: Props) => {
	const { isOpen, onClose, onNotificationRead, notifications, className, ...restProps } = props;

	const user = useSelector((state: any) => state.user.data);

	const Notification = {
		[NotificationType.HW_VERIFIED]: {
			text: 'Задание принято'
		},
		[NotificationType.HW_RETURNED]: {
			text: 'Задание требует доработки'
		},
		[NotificationType.LESSON_COMMENT]: {
			text: 'Добавлен новый комментарий'
		},
	};

	const markWatched = (id: number) => {
		onNotificationRead(id);
	}

	return (
		<BurgerMenu
			isOpen={isOpen}
			pageWrapId={'page-wrap'}
			outerContainerId={'App'}
			className={styles.menu}
			burgerButtonClassName={styles.menu__button}
			overlayClassName={styles.menu__overlay}
			itemListClassName={styles.menu__list}
			customCrossIcon={<Icon icon='close' size={24} />}
			bodyClassName={styles.body}
			onClose={onClose}
			right
			{...restProps}
		>
			{
				notifications?.length === 0 ?
					<h4>Уведомлений пока нет</h4> :
					<ul>
						{
							notifications?.map((item, index) =>
								<li key={index} onClick={() => markWatched(item?.id)}>
									<NavLink
										to={`${user?.role === Roles.CURATOR ? 'curator-courses' : 'courses'}/${item?.payload?.course_id}/modules/${item?.payload?.module_id}/lessons/${item?.payload?.lesson_id}`}
										className={cn(
											styles.notification,
											!item?.was_read ? styles.notification_not_read : ''
										)}
									>
										<span>{formatDate(item?.created, 'dd.LL.yyyy HH:mm')}</span>
										<b>{Notification[item?.type]?.text}</b>
									</NavLink>
								</li>
							)
						}
					</ul>
			}
		</BurgerMenu>
	);
};
