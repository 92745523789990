import axios from 'axios';
import { StorageService } from './storageService';

export let authInterceptor = 0;

export const initAuthInterceptor = () => {
	const storage = StorageService.getInstance();
	const user = storage.localStorageUser || storage.sessionStorageUser;

	authInterceptor = axios.interceptors.request.use(({ ...config }) => {
		if (user?.auth_token) {
			return {
				...config,
				headers: {
					...(config.headers || {}),
					'Authorization': `Token ${user?.auth_token}`,
				},
			};
		}
		return {
			...config,
		};
	});
}

export const rejectAuthInterceptor = () => {
	axios.interceptors.request.eject(authInterceptor);
}


