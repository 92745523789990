import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthLayout, CourseLayout, MainLayout } from './components';
import {
	AuthPage,
	ChangePasswordPage,
	Contacts,
	CourseModules,
	Faq,
	Lesson,
	MethodistLesson,
	ModuleLessons,
	Profile,
	ResetPasswordPage,
	StudentCourses,
	StaffCourses,
	CuratorTasks,
	CuratorTask
} from './pages';
import {
	PrivateCuratorRoute,
	PrivateMethodistRoute,
	PrivateRoute,
	PrivateStudentRoute,
	PublicRoute
} from './utils/routes';
import { StorageService } from './services/storageService';
import { setCurrentUser } from 'reducers/userSlice';
import { Roles } from './enums/roles.enum';

const App: FC = () => {
	const dispatch = useDispatch();
	const storage = StorageService.getInstance();

	const user = storage.localStorageUser || storage.sessionStorageUser;

	if (!!user) {
		dispatch(setCurrentUser(user));
	}

	return (
		<Routes>
			<Route element={<AuthLayout />}>
				<Route path='/auth' element={
					<PublicRoute>
						<AuthPage />
					</PublicRoute>
				} />
				<Route path='/reset-password' element={
					<PublicRoute>
						<ResetPasswordPage />
					</PublicRoute>
				} />
				<Route path='/change-password' element={
					<PublicRoute>
						<ChangePasswordPage />
					</PublicRoute>
				} />
			</Route>
			<Route element={<MainLayout />}>
				<Route path='/courses' element={
					<PrivateStudentRoute>
						<StudentCourses />
					</PrivateStudentRoute>
				} />
				<Route path='/tasks' element={
					<PrivateCuratorRoute>
						<CuratorTasks />
					</PrivateCuratorRoute>
				} />
				<Route path='/tasks/:id' element={
					<PrivateCuratorRoute>
						<CuratorTask />
					</PrivateCuratorRoute>
				} />
				<Route element={<CourseLayout />}>
					<Route path='/courses/:id' element={
						<PrivateRoute>
							<CourseModules />
						</PrivateRoute>
					} />
					<Route path='/courses/:id/modules/:id' element={
						<PrivateRoute>
							<ModuleLessons />
						</PrivateRoute>
					} />
					<Route path='/methodist-courses/:id' element={
						<PrivateMethodistRoute>
							<CourseModules />
						</PrivateMethodistRoute>
					} />
					<Route path='/methodist-courses/:id/modules/:id' element={
						<PrivateMethodistRoute>
							<ModuleLessons />
						</PrivateMethodistRoute>
					} />
					<Route path='/curator-courses/:id' element={
						<PrivateCuratorRoute>
							<CourseModules />
						</PrivateCuratorRoute>
					} />
					<Route path='/curator-courses/:id/modules/:id' element={
						<PrivateCuratorRoute>
							<ModuleLessons />
						</PrivateCuratorRoute>
					} />
				</Route>
				<Route path='/courses/:id/modules/:id/lessons/:id' element={
					<PrivateStudentRoute>
						<Lesson />
					</PrivateStudentRoute>
				} />
				<Route path='profile' element={
					<PrivateRoute>
						<Profile />
					</PrivateRoute>
				} />
				<Route path='contacts' element={
					<PrivateRoute>
						<Contacts />
					</PrivateRoute>
				} />
				<Route path='faq' element={
					<PrivateRoute>
						<Faq />
					</PrivateRoute>
				} />
				<Route path='/methodist-courses' element={
					<PrivateMethodistRoute>
						<StaffCourses />
					</PrivateMethodistRoute>
				} />
				<Route path='/curator-courses' element={
					<PrivateCuratorRoute>
						<StaffCourses />
					</PrivateCuratorRoute>
				} />
				<Route path='/methodist-courses/:id/modules/:id/lessons/:id' element={
					<PrivateMethodistRoute>
						<MethodistLesson />
					</PrivateMethodistRoute>
				} />
				<Route path='/curator-courses/:id/modules/:id/lessons/:id' element={
					<PrivateCuratorRoute>
						<Lesson />
					</PrivateCuratorRoute>
				} />
			</Route>
			{
				user?.role === Roles.STUDENT &&
				<Route path='*' element={
					<Navigate to='/courses' />
				} />
			}
			{
				user?.role === Roles.METHODIST &&
				<Route path='*' element={
					<Navigate to='/methodist-courses' />
				} />
			}
			{
				user?.role === Roles.CURATOR &&
				<Route path='*' element={
					<Navigate to='/curator-courses' />
				} />
			}
			{
				!user?.role &&
				<Route path='*' element={
					<Navigate to='/auth' />
				} />
			}
		</Routes>
	);
}

export default App;
