import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.sass';
import { MobileView } from 'react-device-detect';
import { CourseCard } from './components/CourseCard';
import { getActualStudentCourses, getCompletedStudentCourses } from 'actions/courses';
import { Course } from 'models/course.interface';
import { setCourse } from 'reducers/courseSlice';
import { useDispatch } from 'react-redux';

export const StudentCourses = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState({ actualCoursesLoading: true, completedCoursesLoading: true });
	const [actualCourses, setActualCourses] = useState<Course[]>([]);
	const [completedCourses, setCompletedCourses] = useState<Course[]>([]);

	useEffect(() => {
		dispatch(setCourse(null));
		getActualStudentCourses().then((response) => {
			setActualCourses(response?.results);
			setLoading({ ...loading, actualCoursesLoading: false });
		});

		getCompletedStudentCourses().then((response) => {
			setCompletedCourses(response?.results);
			setLoading({ ...loading, completedCoursesLoading: false });
		});
	}, []);

	const content = useMemo(() => {
		if (loading.actualCoursesLoading && loading.completedCoursesLoading) {
			return <></>;
		}

		return (
			<div className={styles.container}>
				<MobileView>
					<p className={styles.caption}>Мои курсы</p>
				</MobileView>
				{
					actualCourses?.length > 0 &&
					<section>
						<h1>Текущие курсы</h1>
						<div className={styles.list}>
							{
								actualCourses?.map((course, index) => (
									<CourseCard course={course} key={index} className={styles.list__item} />
								))
							}
						</div>
					</section>
				}
				{
					completedCourses?.length > 0 &&
					<section>
						<h1>Завершенные курсы</h1>
						<div className={styles.list}>
							{
								completedCourses?.map((course, index) => (
									<CourseCard
										key={index}
										course={course}
										courseCompleted={true}
										className={styles.list__item}
									/>
								))
							}
						</div>
					</section>
				}
				{
					actualCourses?.length === 0 && completedCourses?.length === 0 &&
					<section className={styles.empty}>
						<p className={styles.empty__text}>
							У вас пока нет ни одного купленного курса. Прейдите на страницу все курсы и выберите что купить
						</p>
					</section>
				}
			</div>
		);
	}, [loading, actualCourses, completedCourses]);

	return <>{content}</>;
};
