import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DEFAULT_URL = '/auth'

export const PrivateRoute: FC = (props) => {
	const {
		children
	} = props;

	const user = useSelector((state: any) => state.user.data);

	return (
		<>
			{
				!user ? (
					<Navigate
						to={DEFAULT_URL}
					/>
				) : children
			}
		</>
	);
};
