import React from 'react';
import styles from './index.module.sass';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { Button, Icon, Radio, Size, TextEditor, TextInput, Theme } from 'components/base';
import { setCurrentEditBlock } from 'reducers/currentEditBlockSlice';

interface Props {
	id: number;
	index: number;
	value: {
		text: string,
		options: Array<{ id: number, value: string, is_correct: boolean }>,
		max_score: number
	};
	updateBlock?: (
		id: number,
		value: {
			text: string,
			options: Array<{ id: number, value: string, is_correct: boolean }>,
			max_score: number
		}) => void;
	className?: string;
}

const TestTaskBlockSchema = Yup.object().shape({
	text: Yup.string()
		.required('Заполните поле'),
	options: Yup.array()
		.of(
			Yup.object().shape({
				value: Yup.string()
					.required('Заполните поле')
			})
		),
	max_score: Yup.number()
		.required('Заполните поле')
});

export const TestTaskBlock = (props: Props) => {
	const { id, index, value, updateBlock = () => {}, className } = props;

	const dispatch = useDispatch();

	const currentEditBlock = useSelector((state: any) => state.currentEditBlock.id);

	const cancel = () => {
		dispatch(setCurrentEditBlock(null));
	}

	return (
		<>
			<h3 className={styles.task__caption}>Задание № {index + 1}</h3>
			{
				currentEditBlock === id ? (
					<Formik
						initialValues={{
							text: value?.text ? value?.text : '',
							options: value?.options ? value?.options : [{ id: 1, value: '', is_correct: true }],
							right_option: value?.options?.find(item => item?.is_correct)?.id || 1,
							max_score: value?.max_score ? value?.max_score : 0
						}}
						validationSchema={TestTaskBlockSchema}
						onSubmit={(
							values
						) => {
							const data = {
								text: values.text,
								options: values.options.map(item => {
									return { ...item, is_correct: item.id === values.right_option }
								}),
								max_score: values.max_score
							}
							updateBlock(id, data);
							dispatch(setCurrentEditBlock(null));
						}}
						enableReinitialize={true}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form className={styles.form} noValidate>
								<div className={className}>
									<TextEditor
										value={values.text}
										onChange={(value) => setFieldValue('text', value)}
									/>
									<FieldArray
										name='options'
										render={optionsArray => (
											<>
												{values.options.map((option, index) => (
													<div key={index}>
														<div className={styles.option}>
															<TextEditor
																value={option.value}
																onChange={(value) => setFieldValue(`options.${index}.value`, value)}
															/>
															{
																index !== 0 &&
																<button
																	onClick={() => optionsArray.remove(index)}
																	className={styles.option__control_delete}
																>
																	<Icon icon='close' size={18} />
																</button>
															}
														</div>
														<div className={cn(styles.field, styles.field_correct)}>
															<span>Поменить вариант ответа, как правильный</span>
															<Radio
																value={option.id}
																checked={values.right_option === option.id}
																onChange={() => {
																	setFieldValue(`options.${index}.is_correct`, true)
																	setFieldValue('right_option', option.id)
																}}
															/>
														</div>
													</div>
												))}
												<Button
													theme={Theme.SECONDARY}
													size={Size.S}
													onClick={() => optionsArray.push({ id: values.options.length + 1, value: '', is_correct: false })}
													className={styles.option__control}
												>
													Добавить вариант ответа
												</Button>
											</>
										)}
									/>
									<div className={styles.field}>
										<span>Количество баллов</span>
										<TextInput
											value={values.max_score.toString()}
											type='number'
											onChange={event => setFieldValue('max_score', +event.target.value)}
											className={styles.field_score}
										/>
									</div>
									<div className={styles.controls}>
										<Button
											theme={Theme.SECONDARY}
											size={Size.S}
											onClick={cancel}
											className={styles.button}
										>
											Отменить
										</Button>
										<Button
											type='submit'
											theme={Theme.PRIMARY}
											size={Size.S}
											className={styles.button}
											disabled={!isValid}
										>
											Сохранить
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<>
						{
							value?.text &&
							<p
								dangerouslySetInnerHTML={{ __html: value?.text }}
								className={styles.task__text}
							></p>
						}
						{
							value?.options?.map((option, index) => (
								<div key={index} className={styles.task__option}>
									<Radio
										value={''}
										onChange={() => {}}
										disabled
									/>
									<p dangerouslySetInnerHTML={{ __html: option?.value }}></p>
								</div>
							))
						}
					</>
				)
			}
		</>
	);
};
