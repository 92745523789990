import { User } from 'models/user.interface';

export class StorageService {
	protected static instance: StorageService;

	set localStorageUser(user: any | undefined) {
		this.setLocalStorage('user', JSON.stringify(user));
	}

	get localStorageUser(): User | undefined {
		return JSON.parse(this.getLocalStorage('user'));
	}

	set sessionStorageUser(user: any | undefined) {
		this.setSessionStorage('user', JSON.stringify(user));
	}

	get sessionStorageUser(): User | undefined {
		return JSON.parse(this.getSessionStorage('user'));
	}

	removeFromLocalStorage(key: string) {
		localStorage.removeItem(key);
	}

	removeFromSessionStorage(key: string) {
		sessionStorage.removeItem(key);
	}

	setLocalStorage(key: string, value: any) {
		localStorage.setItem(key, value);
	}

	getLocalStorage(key: string): any {
		return localStorage.getItem(key);
	}

	setSessionStorage(key: string, value: any) {
		sessionStorage.setItem(key, value);
	}

	getSessionStorage(key: string): any {
		return sessionStorage.getItem(key);
	}

	logout() {
		this.removeFromLocalStorage('user');
		this.removeFromSessionStorage('user');
	}

	static getInstance(): StorageService {
		if (!StorageService.instance) {
			StorageService.instance = new StorageService();
		}
		return StorageService.instance;
	}
}
